import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { beneficiaryListStart } from "../../store/slices/BeneficiariesSlice.jsx";
import Skeleton from "react-loading-skeleton";
import NoDataFoundNew from "../Helper/NoDataFoundNew.jsx";
import { useTranslation } from "react-multi-lang";
import Pagination from "../Helper/Pagination.jsx";

const BeneficiaryList = (props) => {
  const navigate = useNavigate();
  const t = useTranslation("beneficiaries.beneficiaries_list");
  const dispatch = useDispatch();
  const beneficiaryList = useSelector((state) => state.beneficiaries.beneficiaryList);
  const profile = useSelector((state) => state.admin.profile);
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );

  useEffect(() => {
    dispatch(
      beneficiaryListStart({
        skip: 12 * (searchParams.get("page") - 1),
        take: 12,
      })
    );
  }, [searchParams.get("page")]);

  const handlePageClick = ({ selected }) => {
    navigate(`/beneficiary?page=${selected + 1}`);
  };

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          {beneficiaryList.loading ? (
            <>
              <div className="payment-table-header justify-content-between">
                <Skeleton width={130} height={20} borderRadius={5} />
                <div className="payment-table-header-right">
                  <Skeleton width={180} height={45} borderRadius={5} />
                </div>
              </div>
              {[...Array(8)].map((_, i) => (
                <Skeleton key={i} height={60} />
              ))}
            </>
          ) : (
            <>
              <div className="payment-table-header justify-content-between">
                <h3>{t("heading")}</h3>
                <div className="payment-table-header-right">
                  {
                    profile.data.onboarding == 4 && profile.data.mastercard_kyc_status == 3 || profile.data.iban_kyc_status == 3 && profile.data.onboarding == 4 ?
                      <Button
                        className="efi-primary-btn"
                        onClick={() => navigate("/create-beneficiary")}
                      >
                        {t("add_beneficiary")}
                      </Button>
                      :
                      null}
                </div>
              </div>
              {Object.keys(beneficiaryList.data).length > 0 ? (
                beneficiaryList.data.total_beneficiary_accounts > 0 ? (
                  <>
                    <Table className="common-table" responsive>
                      <thead className="common-table-header">
                        <tr>
                          <th>{t("unique_id")}</th>
                          <th>{t("account_name")}</th>
                          <th>{t("account_type")}</th>
                          <th>{t("bank_name")}</th>
                          <th>{t("account_number")}</th>
                          <th>{t("status")}</th>
                          <th>{t("action")}</th>
                        </tr>
                      </thead>
                      <tbody className="common-table-body">
                        {beneficiaryList.data.beneficiary_accounts.map(
                          (account, index) => (
                            <tr key={index}>
                              <td>{account.unique_id}</td>
                              <td>{account.account_name}</td>
                              <td>{account.account_type}</td>
                              <td>{account.receiving_institution_type}</td>
                              <td>{account.account_number}</td>
                              <td>{account.status_formatted}</td>
                              <td className="view-beneficiary-btn">
                                <Button
                                  className="efi-overlay-btn"
                                  onClick={() =>
                                    navigate(`/view-beneficiary/${account.id}`)
                                  }
                                >
                                  {t("view")}
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                    {beneficiaryList.data.total_beneficiary_accounts > 12 ? (
                      <div className="new-billings-pagination-sec">
                        <Pagination
                          page={page}
                          pageCount={Math.ceil(
                            beneficiaryList.data.total_beneficiary_accounts / 12
                          )}
                          handlePageClick={handlePageClick}
                        />
                      </div>
                    ) : null}
                  </>
                ) : (
                  <NoDataFoundNew />
                )
              ) : (
                <NoDataFoundNew />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BeneficiaryList;
