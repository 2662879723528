import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  bankList: getCommonInitialState(),
  createBeneficiary: getCommonInitialState(),
  beneficiaryList: getCommonInitialState(),
  singleBeneficiaryView: getCommonInitialState(),
  updateBeneficiaryStatus: getCommonInitialState(),
  quotesList: getCommonInitialState(),
  createQuote: getCommonInitialState(),
  singleQuoteView: getCommonInitialState(),
  beneficiaryTransactionList: getCommonInitialState(),
  createTransaction: getCommonInitialState(),
  singleTransactionView: getCommonInitialState(),
  countryList: getCommonInitialState(),
  beneficiaryChart: getCommonInitialState(),
};

const BeneficiariesSlice = createSlice({
  name: "beneficiaries",
  initialState,
  reducers: {
    bankListStart: (state) => {
      state.bankList = getCommonStart();
    },
    bankListSuccess: (state, action) => {
      state.bankList = getCommonSuccess(action.payload);
    },
    bankListFailure: (state, action) => {
      state.bankList = getCommonFailure(action.payload);
    },

    createBeneficiaryStart: (state) => {
      state.createBeneficiary = getCommonStart();
    },
    createBeneficiarySuccess: (state, action) => {
      state.createBeneficiary = getCommonSuccess(action.payload);
    },
    createBeneficiaryFailure: (state, action) => {
      state.createBeneficiary = getCommonFailure(action.payload);
    },

    beneficiaryListStart: (state) => {
      state.beneficiaryList = getCommonStart();
    },
    beneficiaryListSuccess: (state, action) => {
      state.beneficiaryList = getCommonSuccess(action.payload);
    },
    beneficiaryListFailure: (state, action) => {
      state.beneficiaryList = getCommonFailure(action.payload);
    },

    singleBeneficiaryViewStart: (state) => {
      state.singleBeneficiaryView = getCommonStart();
    },
    singleBeneficiaryViewSuccess: (state, action) => {
      state.singleBeneficiaryView = getCommonSuccess(action.payload);
    },
    singleBeneficiaryViewFailure: (state, action) => {
      state.singleBeneficiaryView = getCommonFailure(action.payload);
    },

    updateBeneficiaryStatusStart: (state) => {
      state.updateBeneficiaryStatus = getCommonStart();
    },
    updateBeneficiaryStatusSuccess: (state, action) => {
      state.updateBeneficiaryStatus = getCommonSuccess(action.payload);
    },
    updateBeneficiaryStatusFailure: (state, action) => {
      state.updateBeneficiaryStatus = getCommonFailure(action.payload);
    },

    quotesListStart: (state) => {
      state.quotesList = getCommonStart();
    },
    quotesListSuccess: (state, action) => {
      state.quotesList = getCommonSuccess(action.payload);
    },
    quotesListFailure: (state, action) => {
      state.quotesList = getCommonFailure(action.payload);
    },

    createQuoteStart: (state) => {
      state.createQuote = getCommonStart();
    },
    createQuoteSuccess: (state, action) => {
      state.createQuote = getCommonSuccess(action.payload);
    },
    createQuoteFailure: (state, action) => {
      state.createQuote = getCommonFailure(action.payload);
    },

    singleQuoteViewStart: (state) => {
      state.singleQuoteView = getCommonStart();
    },
    singleQuoteViewSuccess: (state, action) => {
      state.singleQuoteView = getCommonSuccess(action.payload);
    },
    singleQuoteViewFailure: (state, action) => {
      state.singleQuoteView = getCommonFailure(action.payload);
    },

    beneficiaryTransactionListStart: (state) => {
      state.beneficiaryTransactionList = getCommonStart();
    },
    beneficiaryTransactionListSuccess: (state, action) => {
      state.beneficiaryTransactionList = getCommonSuccess(action.payload);
    },
    beneficiaryTransactionListFailure: (state, action) => {
      state.beneficiaryTransactionList = getCommonFailure(action.payload);
    },

    createTransactionStart: (state) => {
      state.createTransaction = getCommonStart();
    },
    createTransactionSuccess: (state, action) => {
      state.createTransaction = getCommonSuccess(action.payload);
    },
    createTransactionFailure: (state, action) => {
      state.createTransaction = getCommonFailure(action.payload);
    },

    singleTransactionViewStart: (state) => {
      state.singleTransactionView = getCommonStart();
    },
    singleTransactionViewSuccess: (state, action) => {
      state.singleTransactionView = getCommonSuccess(action.payload);
    },
    singleTransactionViewFailure: (state, action) => {
      state.singleTransactionView = getCommonFailure(action.payload);
    },

    countryListStart: (state) => {
      state.countryList = getCommonStart();
    },
    countryListSuccess: (state, action) => {
      state.countryList = getCommonSuccess(action.payload);
    },
    countryListFailure: (state, action) => {
      state.countryList = getCommonFailure(action.payload);
    },

    // Dashboard Charts
    beneficiaryChartStart: (state, action) => {
      state.beneficiaryChart = getCommonStart();
    },
    beneficiaryChartSuccess: (state, action) => {
      state.beneficiaryChart = getCommonSuccess(action.payload);
    },
    beneficiaryChartFailure: (state, action) => {
      state.beneficiaryChart = getCommonFailure(action.payload);
    },
  },
});

export const {
  bankListStart,
  bankListSuccess,
  bankListFailure,
  createBeneficiaryStart,
  createBeneficiarySuccess,
  createBeneficiaryFailure,
  beneficiaryListStart,
  beneficiaryListSuccess,
  beneficiaryListFailure,
  singleBeneficiaryViewStart,
  singleBeneficiaryViewSuccess,
  singleBeneficiaryViewFailure,
  updateBeneficiaryStatusStart,
  updateBeneficiaryStatusSuccess,
  updateBeneficiaryStatusFailure,
  quotesListStart,
  quotesListSuccess,
  quotesListFailure,
  createQuoteStart,
  createQuoteSuccess,
  createQuoteFailure,
  singleQuoteViewStart,
  singleQuoteViewSuccess,
  singleQuoteViewFailure,
  beneficiaryTransactionListFailure,
  beneficiaryTransactionListStart,
  beneficiaryTransactionListSuccess,
  createTransactionStart,
  createTransactionSuccess,
  createTransactionFailure,
  singleTransactionViewStart,
  singleTransactionViewSuccess,
  singleTransactionViewFailure,
  countryListStart,
  countryListSuccess,
  countryListFailure,
  beneficiaryChartFailure,
  beneficiaryChartStart,
  beneficiaryChartSuccess,
} = BeneficiariesSlice.actions;

export default BeneficiariesSlice.reducer;
