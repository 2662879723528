import React, { useState, useEffect } from "react";
import { Button, Table, Dropdown } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import QuoteTransferjsx from "./QuoteTransfer.jsx";
import { useSelector, useDispatch } from "react-redux";
import { quotesListStart } from "../../store/slices/BeneficiariesSlice.jsx";
import Skeleton from "react-loading-skeleton";
import NoDataFoundNew from "../Helper/NoDataFoundNew.jsx";
import { useTranslation } from "react-multi-lang";
import MoneyTransferModal from "../Payment/MoneyTransferModal.jsx";
import Pagination from "../Helper/Pagination.jsx";

const QuoteIndex = (props) => {
  const t = useTranslation("quote.quote_list");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quotesList = useSelector((state) => state.beneficiaries.quotesList);
  const profile = useSelector((state) => state.admin.profile);
  const [modalShow, setModalShow] = useState(false);
  const [sendMoneyModal, setSendMoneyModal] = useState(false);
  const [quoteId, setQuoteId] = useState("");
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );

  useEffect(() => {
    dispatch(
      quotesListStart({
        skip: 12 * (searchParams.get("page") - 1),
        take: 12,
      })
    );
  }, [searchParams.get("page")]);

  const closeSendMoneyModalModal = () => {
    setSendMoneyModal(false);
  };

  const handlePageClick = ({ selected }) => {
    navigate(`/quote?page=${selected + 1}`);
  };

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          {quotesList.loading ? (
            <>
              <div className="payment-table-header justify-content-between">
                <Skeleton height={20} width={100} borderRadius={5} />
                <div className="payment-table-header-right">
                  <Skeleton height={50} width={150} borderRadius={5} />
                </div>
              </div>
              {[...Array(8)].map(() => (
                <Skeleton height={60} />
              ))}
            </>
          ) : (
            <>
              <div className="payment-table-header justify-content-between">
                <h3>{t("heading")}</h3>
                <div className="payment-table-header-right">
                  {
                    profile.data.onboarding == 4 ?
                      <Button
                        className="efi-primary-btn"
                        onClick={() => setModalShow(true)}
                      >
                        {t("create_quote")}
                      </Button>
                      :
                      null
                  }
                </div>
              </div>
              {Object.keys(quotesList.data).length > 0 &&
                quotesList.data.total_quotes > 0 ? (
                <>
                  <Table className="common-table" responsive>
                    <thead className="common-table-header">
                      <tr>
                        <th>{t("quote_id")}</th>
                        {/* <th>IBAN</th> */}
                        <th>{t("receiving_bank")}</th>
                        <th>{t("receiving_currency")}</th>
                        <th>{t("amount")}</th>
                        <th>{t("status")}</th>
                        <th>{t("updated_at")}</th>
                        <th>{t("action")}</th>
                      </tr>
                    </thead>
                    {quotesList.data.quotes.map((quote, index) => (
                      <tbody className="common-table-body">
                        <tr key={quote.unique_id}>
                          <td>{quote.unique_id}</td>
                          {/* <td>{item.iban}</td> */}
                          <td>
                            {
                              quote.beneficiary_account
                                .receiving_institution_type
                            }
                          </td>
                          <td>
                            {quote.beneficiary_account.receiving_currency}
                          </td>
                          <td>{quote.amount}</td>
                          <td>{quote.status_formatted}</td>
                          <td>{quote.updated_at_formatted}</td>
                          <td className="delete-contact-btn-sec">
                            <div className="d-flex flex-column justify-content-center ">
                              {quote.status == 0 && (
                                <Button
                                  className="efi-overlay-btn "
                                  onClick={() => {
                                    setQuoteId(quote.id);
                                    setSendMoneyModal(true);
                                  }}
                                >
                                  {t("send_money")}
                                </Button>
                              )}
                              <Button
                                className="efi-overlay-btn mt-1 "
                                onClick={() => {
                                  navigate("/quote-details", {
                                    state: quote.id,
                                  });
                                }}
                              >
                                {t("view")}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>
                  {quotesList.data.total_quotes > 12 ? (
                    <div className="new-billings-pagination-sec">
                      <Pagination
                        page={page}
                        pageCount={Math.ceil(quotesList.data.total_quotes / 12)}
                        handlePageClick={handlePageClick}
                      />
                    </div>
                  ) : null}
                </>
              ) : (
                <NoDataFoundNew />
              )}
            </>
          )}
        </div>
        {sendMoneyModal ? (
          <MoneyTransferModal
            sendMoneyModal={sendMoneyModal}
            quoteId={quoteId}
            setSendMoneyModal={setSendMoneyModal}
            closeSendMoneyModalModal={closeSendMoneyModalModal}
          />
        ) : null}

        <QuoteTransferjsx show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </>
  );
};
export default QuoteIndex;
