import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Button,
  Container,
  Image,
  Row,
  Col,
  InputGroup,
  Carousel,
} from "react-bootstrap";
import "./UserAuth.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { registerStart } from "../../store/slices/AdminSlice";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../Helper/Loader";
import * as Yup from "yup";
import OtpVerification from "./OtpVerification";
import Select from "react-select";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  getCountries,
} from "react-phone-number-input";
import { Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Authenticator from "./Authenticator";
import { values } from "lodash";

const filteredCountries = getCountries().filter(
  (country) =>
    ![
      "AF",
      "BY",
      "CF",
      "CN",
      "CU",
      "CD",
      "ET",
      "IN",
      "IR",
      "IQ",
      "LY",
      "ML",
      "NI",
      "KP",
      "SO",
      "SS",
      "SD",
      "SY",
      "US",
      "VE",
      "YE",
      "ZW",
    ].includes(country)
);

const RegisterIndex = (props) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("register");
  const formRef = useRef();
  const register = useSelector((state) => state.admin.register);
  const userId = useSelector((state) => state.admin.userId);
  const [showPassword, setShowPassword] = useState({
    password: true,
    confirmPassword: true,
  });
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [agreement, setAgreement] = useState(false);
  const [authenticatorModal, setAuthenticatorModal] = useState(false);
  const emailVerify = useSelector((state) => state.admin.emailVerify);
  const profile = useSelector((state) => state.admin.profile);

  const closeOtpVerifyModal = () => {
    setShowOTPVerifyModal(false);
  };

  const registerSchema = Yup.object().shape({
    title: Yup.string().required(t("title.required")),
    first_name: Yup.string()
      .required(t("name.required"))
      .matches(/^\S.*$/, t("name.required"))
      .matches(/^[A-Za-z\s]+$/, t("name.invalid"))
      .min(3, t("name.min_length_invalid"))
      .trim(),
    middle_name: Yup.string()
      .matches(/^\S.*$/, t("name.required"))
      .matches(/^[A-Za-z\s]+$/, t("name.invalid"))
      .min(3, t("name.min_length_invalid"))
      .trim(),
    last_name: Yup.string()
      .required(t("name.required"))
      .matches(/^[A-Za-z\s]+$/, t("name.invalid"))
      .min(1, t("last_name.min_length_invalid"))
      .trim(),
    email: Yup.string()
      .email(t("email.invalid"))
      .required(t("email.required"))
      .min(12, t("email.min_length_invalid"))
      .max(64, t("email.max_length_invalid")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^\S*$/, t("password.no_spaces"))
      .test(
        "complexity",
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        (value) => {
          if (!value) return false; // Skip validation if value is not provided
          const hasUppercase = /[A-Z]/.test(value);
          const hasLowercase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
          return hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
        }
      )
      .min(6, t("password.min_length_invalid")),
    password_confirmation: Yup.string()
      .required(t("password_confirmation.required"))
      .matches(/^\S*$/, t("password_confirmation.no_spaces"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("password_confirmation.invalid")
        ),
      }),
    mobile: Yup.string()
      .required(t("mobile.required"))
      .matches(/^(?=.*[0-9])/, t("mobile.invalid"))
      .min(6, t("mobile.min_length_invalid"))
      .max(15, t("mobile.max_length_invalid")),
  });

  const togglePasswordVisibility = (value) => {
    if (value == 1)
      setShowPassword((prevState) => ({
        ...prevState,
        password: !prevState.password,
      }));
    else
      setShowPassword((prevState) => ({
        ...prevState,
        confirmPassword: !prevState.confirmPassword,
      }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !register.loading &&
      Object.keys(register.data).length > 0
    ) {
      register.data.user.email_status == 0
        ? setShowOTPVerifyModal({
            ...register.data.user,
            type: "email",
          })
        : register.data.user.is_initial_two_step_auth_enabled == 0 &&
          register.data.google2fa_secret
        ? setAuthenticatorModal({
            email: formRef.current?.values.email,
            password: formRef.current?.values.password,
          })
        : navigate("/onboarding");
    }
    setSkipRender(false);
  }, [register]);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "4px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #dee2e6!important",
      borderRadius: "4px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878E96",
      fontSize: "0.9em",
      fontWeight: "500",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#dde1e6",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f7f7f7",
          color: "#000",
        },
      };
    },
  };

  const [selectedTitle, setSelectedTitle] = useState(null);

  const title_options = [
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
  ];

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile.required"));
      return false;
    }
  };

  const handleRegister = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");

      const invalidCodes = [
        "93", // Afghanistan (AF)
        "375", // Belarus (BY)
        "236", // Central African Republic (CF)
        "86", // China (CN)
        "53", // Cuba (CU)
        "243", // Democratic Republic of the Congo (CD)
        "251", // Ethiopia (ET)
        "91", // India (IN)
        "98", // Iran (IR)
        "964", // Iraq (IQ)
        "218", // Libya (LY)
        "223", // Mali (ML)
        "505", // Nicaragua (NI)
        "850", // North Korea (KP)
        "252", // Somalia (SO)
        "211", // South Sudan (SS)
        "249", // Sudan (SD)
        "963", // Syria (SY)
        "1", // United States (US)
        "58", // Venezuela (VE)
        "967", // Yemen (YE)
        "263", // Zimbabwe (ZW)
      ];

      if (invalidCodes.includes(countryCode)) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return;
      }

      dispatch(
        registerStart({
          ...values,
          mobile: mobile,
          mobile_country_code: countryCode,
          browser_id: userId,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      if (emailVerify.data.google2fa_secret) {
        setShowOTPVerifyModal(false);
        setAuthenticatorModal({
          email: formRef.current?.values.email,
          password: formRef.current?.values.password,
        });
      }
    }
    setSkipRender(false);
  }, [emailVerify]);

  // useEffect(() => {
  //   if (
  //     !skipRender &&
  //     !profile.loading &&
  //     Object.keys(profile.data).length > 0
  //   ) if(profile.data.is_initial_two_step_auth_enabled == 1) {
  //     navigate("/onboarding");
  //   }
  //   setSkipRender(false);
  // }, [profile]);

  return (
    <>
      <div className="login-page-sec">
        <div className="login-page-box">
          <Row className=" g-0">
            <Col md={6}>
              <div className="login-left-card">
                <div className="login-auth-top-frame">
                  <LazyLoadImage
                    effect="blur"
                    className="login-auth-logo"
                    src={
                      window.location.origin + "/img/efi-bank-white-logo.png"
                    }
                  />
                </div>
                <div className="login-auth-middle-frame">
                  <LazyLoadImage
                    effect="blur"
                    className="login-auth-avater"
                    src={
                      window.location.origin +
                      "/img/feature/auth/login-auth.png"
                    }
                  />
                  <div className="login-auth-info">
                    <h2>{t("auth_content.heading")}</h2>
                    <p>{t("auth_content.content")}</p>
                  </div>
                </div>
                <div className="login-auth-footer-frame">
                  <Carousel>
                    <Carousel.Item>
                      <div className="carousel-content">
                        <h3>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#0194ff"
                            class="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                          </svg>
                          <span>
                            {" "}
                            {t(
                              "auth_content.identity_verification.heading"
                            )}{" "}
                          </span>
                        </h3>
                        <p>{t("auth_content.identity_verification.content")}</p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="carousel-content">
                        <h3>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#0194ff"
                            class="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                          </svg>
                          <span>
                            {" "}
                            {t("auth_content.banking_simplified.heading")}{" "}
                          </span>
                        </h3>
                        <p>{t("auth_content.banking_simplified.content")}</p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="carousel-content">
                        <h3>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#0194ff"
                            class="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                          </svg>
                          <span>
                            {" "}
                            {t("auth_content.continuous_support.heading")}{" "}
                          </span>
                        </h3>
                        <p>{t("auth_content.continuous_support.content")}</p>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="login-page-form-box">
                <Row className="align-items-center justify-content-center g-0">
                  <Col lg={9} xl={9} md={12}>
                    <div className="efi-bank-auth-form">
                      <div className="efi-bank-auth-title">
                        <h3>Welcome Aboard!</h3>
                        <p>
                          Get started by entering your full name, email, phone
                          number, and creating a secure password to join EFI
                          Bank's innovative platform.
                        </p>
                      </div>
                      <div className="efi-bank-auth-form-box">
                        <Formik
                          initialValues={{
                            title: "",
                            first_name: "",
                            middle_name: "",
                            last_name: "",
                            email: "",
                            mobile: "",
                            password: "",
                            password_confirmation: "",
                            // service_type: "",
                          }}
                          validationSchema={registerSchema}
                          onSubmit={agreement && handleRegister}
                          innerRef={formRef}
                        >
                          {({ setFieldValue, values, touched, errors }) => (
                            <FORM className="auth-form-sec">
                              <div className="xs-marg-btm">
                                <Row className="gx-1">
                                  <Col lg={3} className="resp-mrg-btm-xs">
                                    <div className="efi-bank-auth-input-group">
                                      <Select
                                        styles={customStyles}
                                        defaultValue={selectedTitle}
                                        onChange={(option) => {
                                          setSelectedTitle(option);
                                          setFieldValue("title", option.value);
                                        }}
                                        className={`${
                                          errors.title &&
                                          touched.title
                                            ? "input-group-error css-1l8uzyq-container"
                                            : ""
                                        }`}
                                        options={title_options}
                                        name="title"
                                        placeholder={<>Title</>}
                                        isSearchable={false}
                                      />
                                      <ErrorMessage
                                        component={"div"}
                                        name="title"
                                        className="errorMsg"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={9}>
                                    <div className="efi-bank-auth-input-group">
                                      <InputGroup
                                        className={`${
                                          errors.first_name &&
                                          touched.first_name
                                            ? "input-group-error"
                                            : ""
                                        }`}
                                      >
                                        <InputGroup.Text>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="#c3c7ca"
                                              d="M12 12a5 5 0 100-10 5 5 0 000 10z"
                                            ></path>
                                            <path
                                              fill="#171717"
                                              d="M12 14.5c-5.01 0-9.09 3.36-9.09 7.5 0 .28.22.5.5.5h17.18c.28 0 .5-.22.5-.5 0-4.14-4.08-7.5-9.09-7.5z"
                                            ></path>
                                          </svg>
                                        </InputGroup.Text>
                                        <Field
                                          className="form-control"
                                          name="first_name"
                                          type="text"
                                          placeholder="First Name"
                                          aria-label="text"
                                          aria-describedby="text-addon"
                                          maxLength={16}
                                        />
                                      </InputGroup>
                                      <ErrorMessage
                                        component={"div"}
                                        name="first_name"
                                        className="errorMsg"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="xs-marg-btm efi-bank-auth-input-group">
                                <Row className="g-1">
                                  <Col lg={6} className="resp-mrg-btm-xs name">
                                    <InputGroup
                                      className={`${
                                        errors.middle_name &&
                                        touched.middle_name
                                          ? "input-group-error"
                                          : ""
                                      }`}
                                    >
                                      <InputGroup.Text>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="#c3c7ca"
                                            d="M12 12a5 5 0 100-10 5 5 0 000 10z"
                                          ></path>
                                          <path
                                            fill="#171717"
                                            d="M12 14.5c-5.01 0-9.09 3.36-9.09 7.5 0 .28.22.5.5.5h17.18c.28 0 .5-.22.5-.5 0-4.14-4.08-7.5-9.09-7.5z"
                                          ></path>
                                        </svg>
                                      </InputGroup.Text>
                                      <Field
                                        className="form-control"
                                        name="middle_name"
                                        type="text"
                                        placeholder="Middle Name"
                                        aria-label="text"
                                        aria-describedby="text-addon"
                                        maxLength={16}
                                      />
                                    </InputGroup>
                                    <ErrorMessage
                                      component={"div"}
                                      name="middle_name"
                                      className="errorMsg"
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <div className="efi-bank-auth-input-group">
                                      <InputGroup
                                        className={`${
                                          errors.last_name && touched.last_name
                                            ? "input-group-error"
                                            : ""
                                        }`}
                                      >
                                        <InputGroup.Text>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="#c3c7ca"
                                              d="M12 12a5 5 0 100-10 5 5 0 000 10z"
                                            ></path>
                                            <path
                                              fill="#171717"
                                              d="M12 14.5c-5.01 0-9.09 3.36-9.09 7.5 0 .28.22.5.5.5h17.18c.28 0 .5-.22.5-.5 0-4.14-4.08-7.5-9.09-7.5z"
                                            ></path>
                                          </svg>
                                        </InputGroup.Text>
                                        <Field
                                          className="form-control"
                                          name="last_name"
                                          type="text"
                                          placeholder="Last Name"
                                          aria-label="text"
                                          aria-describedby="text-addon"
                                          maxLength={16}
                                        />
                                      </InputGroup>
                                      <ErrorMessage
                                        component={"div"}
                                        name="last_name"
                                        className="errorMsg"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="mb-3 efi-bank-auth-input-group">
                                <InputGroup
                                  className={`${
                                    errors.email && touched.email
                                      ? "input-group-error"
                                      : ""
                                  }`}
                                >
                                  <InputGroup.Text>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="#c3c7ca"
                                        d="M17 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5z"
                                      ></path>
                                      <path
                                        fill="#171717"
                                        d="M12 12.87c-.84 0-1.69-.26-2.34-.79l-3.13-2.5a.748.748 0 01.93-1.17l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12.26.32.21.8-.12 1.05l-3.13 2.5c-.64.53-1.49.79-2.33.79z"
                                      ></path>
                                    </svg>
                                  </InputGroup.Text>
                                  <Field
                                    className="form-control"
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                    aria-label="Email"
                                    aria-describedby="email-addon"
                                  />
                                </InputGroup>
                                <ErrorMessage
                                  component={"div"}
                                  name="email"
                                  className="errorMsg"
                                />
                              </div>
                              <div className="mb-3 efi-bank-auth-input-group">
                                <InputGroup
                                  className={`register-phone-input ${
                                    errors.mobile && touched.mobile
                                      ? "input-group-error"
                                      : ""
                                  }`}
                                >
                                  <PhoneInput
                                    name="mobile"
                                    defaultCountry={"AE"}
                                    placeholder={t("mobile.placeholder")}
                                    onChange={(value) => {
                                      setFieldValue("mobile", value)
                                    }}
                                    // onBlur={() => formRef.current.submitForm()}
                                    international
                                    countries={filteredCountries}
                                    className={`${
                                      touched.mobile && errors.mobile ? "is-invalid" : ""
                                    }`}
                                    maxLength={17}
                                  />
                                </InputGroup>
                                <ErrorMessage
                                  component={"div"}
                                  name="mobile"
                                  className="errorMsg"
                                />
                              </div>
                              <div className="mb-3 efi-bank-auth-input-group">
                                <InputGroup
                                  className={`${
                                    errors.password && touched.password
                                      ? "input-group-error password"
                                      : ""
                                  }`}
                                >
                                  <InputGroup.Text>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="#c3c7ca"
                                        d="M19.79 4.22c-2.96-2.95-7.76-2.95-10.7 0-2.07 2.05-2.69 5-1.89 7.6l-4.7 4.7c-.33.34-.56 1.01-.49 1.49l.3 2.18c.11.72.78 1.4 1.5 1.5l2.18.3c.48.07 1.15-.15 1.49-.5l.82-.82c.2-.19.2-.51 0-.71l-1.94-1.94a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l1.95 1.95c.19.19.51.19.7 0l2.12-2.11c2.59.81 5.54.18 7.6-1.87 2.95-2.95 2.95-7.76 0-10.71zM14.5 12a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"
                                      ></path>
                                      <path
                                        fill="#171717"
                                        d="M14.5 12a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
                                      ></path>
                                    </svg>
                                  </InputGroup.Text>
                                  <Field
                                    className="form-control"
                                    name="password"
                                    type={
                                      !showPassword.password
                                        ? "text"
                                        : "password"
                                    }
                                    placeholder="Password"
                                    aria-label="Password"
                                    aria-describedby="Password-addon"
                                    maxLength={16}
                                  />
                                  <InputGroup.Text
                                    className="password-icon"
                                    onClick={() => togglePasswordVisibility(1)}
                                  >
                                    {!showPassword.password ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="#c3c7ca"
                                          d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                                        ></path>
                                        <path
                                          fill="#171717"
                                          d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                                        ></path>
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="#c3c7ca"
                                          d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                                        ></path>
                                        <path
                                          fill="#171717"
                                          d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                                        ></path>
                                        <path
                                          fill="#c3c7ca"
                                          d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                                        ></path>
                                        <path
                                          fill="#171717"
                                          d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                                        ></path>
                                      </svg>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                                <ErrorMessage
                                  component={"div"}
                                  name="password"
                                  className="errorMsg passwd"
                                />
                              </div>
                              <div className="mb-3 efi-bank-auth-input-group">
                                <InputGroup
                                  className={`${
                                    errors.password_confirmation &&
                                    touched.password_confirmation
                                      ? "input-group-error"
                                      : ""
                                  }`}
                                >
                                  <InputGroup.Text>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="#c3c7ca"
                                        d="M19.79 4.22c-2.96-2.95-7.76-2.95-10.7 0-2.07 2.05-2.69 5-1.89 7.6l-4.7 4.7c-.33.34-.56 1.01-.49 1.49l.3 2.18c.11.72.78 1.4 1.5 1.5l2.18.3c.48.07 1.15-.15 1.49-.5l.82-.82c.2-.19.2-.51 0-.71l-1.94-1.94a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l1.95 1.95c.19.19.51.19.7 0l2.12-2.11c2.59.81 5.54.18 7.6-1.87 2.95-2.95 2.95-7.76 0-10.71zM14.5 12a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"
                                      ></path>
                                      <path
                                        fill="#171717"
                                        d="M14.5 12a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
                                      ></path>
                                    </svg>
                                  </InputGroup.Text>
                                  <Field
                                    className="form-control"
                                    name="password_confirmation"
                                    type={
                                      !showPassword.confirmPassword
                                        ? "text"
                                        : "password"
                                    }
                                    placeholder="Confirm Password"
                                    aria-label="Password"
                                    aria-describedby="Password-addon"
                                    maxLength={16}
                                  />
                                  <InputGroup.Text
                                    className="password-icon"
                                    onClick={() => togglePasswordVisibility(0)}
                                  >
                                    {!showPassword.confirmPassword ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="#c3c7ca"
                                          d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                                        ></path>
                                        <path
                                          fill="#171717"
                                          d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                                        ></path>
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="#c3c7ca"
                                          d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                                        ></path>
                                        <path
                                          fill="#171717"
                                          d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                                        ></path>
                                        <path
                                          fill="#c3c7ca"
                                          d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                                        ></path>
                                        <path
                                          fill="#171717"
                                          d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                                        ></path>
                                      </svg>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                                <ErrorMessage
                                  component={"div"}
                                  name="password_confirmation"
                                  className="errorMsg"
                                />
                              </div>
                              <div className="static-link-sec">
                                <Switch
                                  onChange={() => setAgreement(!agreement)}
                                />
                                <p>
                                  By signin up, you agree to our{" "}
                                  <a
                                    target="blank"
                                    href={
                                      window.location.origin + "/page/terms"
                                    }
                                  >
                                    Terms of Use
                                  </a>{" "}
                                  and{" "}
                                  <a
                                    target="blank"
                                    href={
                                      window.location.origin + "/page/privacy"
                                    }
                                  >
                                    Privacy Policy
                                  </a>
                                </p>
                              </div>
                              <div className="efi-auth-signin">
                                <Button
                                  className="efi-primary-btn w-100"
                                  type="submit"
                                  disabled={
                                    register.buttonDisable || !agreement
                                  }
                                >
                                  {register.buttonDisable ? (
                                    <ButtonLoader />
                                  ) : (
                                    t("register_btn.text")
                                  )}
                                </Button>
                                <p>
                                  {t("have_account")}{" "}
                                  <span className="sign-up">
                                    <Link to="/login">{t("login")}</Link>
                                  </span>
                                </p>
                              </div>
                            </FORM>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {showOTPVerifyModal ? (
        <OtpVerification
          showOtpVerify={showOTPVerifyModal}
          closeOtpVerifyModal={closeOtpVerifyModal}
          type="email"
        />
      ) : null}
      {authenticatorModal ? (
        <Authenticator
          authenticatorModal={authenticatorModal}
          authentication={
            register.data?.google2fa_secret ? register : emailVerify
          }
          login={0}
          closeAuthenticatorModal={() => setAuthenticatorModal(false)}
        />
      ) : null}
    </>
  );
};

export default RegisterIndex;
