import React from "react";
import { Link } from "react-router-dom";
import { Image, Row, Col } from "react-bootstrap";
import "./Onboarding.css";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";

const OnboardingWelcome = (props) => {
  
  const t = useTranslation("onboarding.onboarding_welcome");
  const profile = useSelector(state => state.admin.profile);

  return (
    <>
      <div className="welcome-onboarding-sec">
        <Row className="justify-content-center">
          <Col md={5}>
            <div className="welcome-onboarding-box">
              <div className="welcome-onboarding-img-sec">
                <Image
                  className="welcome-onboarding-img"
                  src={window.location.origin + "/img/welcome-img.svg"}
                />
              </div>
              <div className="welcome-onboarding-info-sec">
                <h4>{t("heading")}</h4>
                <p>{t("content")}</p>
                <Link
                  to="/user-onboarding"
                  className="efi-primary-btn mb-3"
                >
                  {t("initiate_onboarding")}
                </Link>
                {profile.data.onboarding >= 3 ? <div className="onboarding-auth-footer-skip">
                  <Link to="/account" className="efi-overlay-btn">
                    {t("skip")}
                  </Link>
                </div> : null}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OnboardingWelcome;
