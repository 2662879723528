import React, { useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { singleTransactionViewStart } from "../../store/slices/BeneficiariesSlice.jsx";
import Skeleton from "react-loading-skeleton";
import NoDataFoundNew from "../Helper/NoDataFoundNew.jsx";
import { useTranslation } from "react-multi-lang";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage.jsx";
import NoDataFound from "../Helper/NoDataFound.jsx";

const BeneficiaryView = () => {
  const location = useLocation();
  const t = useTranslation("beneficiaries.beneficiary_view");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const singleTransactionView = useSelector(
    (state) => state.beneficiaries.singleTransactionView
  );
  useEffect(() => {
    dispatch(
      singleTransactionViewStart({ beneficiary_transaction_id: location.state })
    );
  }, []);

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "code-status payment-initiated";
      case 2:
        return "code-status payment-processing";
      case 3:
        return "code-status success";
      case 4:
        return "code-status payment-failed";
      default:
        return "code-status payment-initiated";
    }
  };


  return (
    <div className="full-body-card-wrapped">
      <div className="beneficiary-wrapped">
        <div className="beneficiary-frame-head">
          <div className="quote-frame-titles">
            {singleTransactionView.loading ? (
              <>
                <Skeleton height={30} width={250} />
              </>
            ) : (
              <>
                <Link to={-1}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    data-name="Layer 2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"></path>
                  </svg>
                </Link>
                <h2>{t("heading")}</h2>
              </>
            )}
          </div>
        </div>
        <div className="beneficiary-frame-content">
          {singleTransactionView.loading ? (
            <>
              <Skeleton height={380} borderRadius={10} />
              <Skeleton height={500} borderRadius={10} className="mt-4" />
              <Skeleton height={650} borderRadius={10} className="mt-4" />
              <Skeleton height={150} borderRadius={10} className="mt-4" />
              <Skeleton height={330} borderRadius={10} className="mt-4" />
            </>
          ) : Object.keys(singleTransactionView.data).length > 0 ? (
            <>
              <div className="beneficiary-info-card">
                <div className="beneficiary-info-head">
                  <h3>{t("transaction_details")}</h3>
                </div>
                <div className="beneficiary-code-card">
                  <div className="beneficiary-code-sec">
                    <div className="code-frame">
                      <h5>
                        <span>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.id
                          }
                        </span>
                        <CopyToClipboard
                          text={
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.id
                          }
                        >
                          <Button
                            className="copy-btn"
                            onClick={() =>
                              getSuccessNotificationMessage("Copied!")
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#171717"
                                d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                                opacity="0.4"
                              ></path>
                            </svg>
                          </Button>
                        </CopyToClipboard>
                      </h5>
                    </div>
                    <div className={getStatusClass(singleTransactionView.data.beneficiary_transaction.status)}>
                      {
                        singleTransactionView.data.beneficiary_transaction
                          .status_formatted
                      }
                    </div>
                  </div>
                  <div className="beneficiary-frame-info card-flex">
                    <div className="benefi-info-card">
                      <h4>{t("recipient_amount")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.recipient_amount.currency
                        }
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.recipient_amount.amount
                        }
                      </p>
                    </div>
                    <div className="benefi-info-icons">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 512 512"
                      >
                        <path d="M1.655 218.199a21.693 21.693 0 014.701-23.64l98.699-98.699c8.475-8.475 22.208-8.475 30.682 0 8.472 8.47 8.472 22.208 0 30.678l-61.665 61.665h416.233c11.981 0 21.695 9.712 21.695 21.695s-9.714 21.695-21.695 21.695H21.697a21.695 21.695 0 01-20.042-13.394zm488.648 62.208H21.695C9.714 280.407 0 290.119 0 302.102s9.714 21.695 21.695 21.695h416.233l-61.667 61.665c-8.47 8.47-8.472 22.208 0 30.678a21.626 21.626 0 0015.341 6.356 21.63 21.63 0 0015.341-6.356l98.701-98.699a21.695 21.695 0 00-15.341-37.034z"></path>
                      </svg>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("converted_amount")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.converted_amount.currency
                        }
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.converted_amount.amount
                        }
                      </p>
                    </div>
                    <div className="benefi-info-icons">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 512 512"
                      >
                        <path d="M256 439.98c-13.81 0-25-11.19-25-25V97.02c0-13.81 11.19-25 25-25s25 11.19 25 25v317.96c0 13.81-11.19 25-25 25z"></path>
                        <path d="M414.98 281H97.02c-13.81 0-25-11.19-25-25s11.19-25 25-25h317.96c13.81 0 25 11.19 25 25s-11.19 25-25 25z"></path>
                      </svg>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("total_fee")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.quote.fees.total.currency
                        }
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.quote.fees.total.amount
                        }
                      </p>
                    </div>
                    <div className="benefi-info-icons">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        x="0"
                        y="0"
                        enableBackground="new 0 0 121.805 121.805"
                        version="1.1"
                        viewBox="0 0 121.805 121.805"
                        xmlSpace="preserve"
                      >
                        <path d="M7.308 85.264h107.188c4.037 0 7.309-3.271 7.309-7.31s-3.271-7.309-7.309-7.309H7.308C3.271 70.646 0 73.916 0 77.954s3.271 7.31 7.308 7.31zM7.308 51.158h107.188a7.309 7.309 0 100-14.617H7.308A7.307 7.307 0 000 43.849a7.308 7.308 0 007.308 7.309z"></path>
                      </svg>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("charged_amount")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.charged_amount.currency
                        }
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.charged_amount.amount
                        }
                      </p>
                    </div>
                  </div>
                  <div className="benefici-space"></div>
                  <div className="beneficiary-code-sec">
                    <div className="benefi-info-card">
                      <h4>{t("transaction_sub_status")}</h4>
                      <p>   {
                        singleTransactionView.data.beneficiary_transaction
                          .beneficiary_transaction.message
                      }</p>
                    </div>
                    {singleTransactionView.data.beneficiary_transaction
                      .sub_status !== "" && (
                        <div className={getStatusClass(singleTransactionView.data.beneficiary_transaction.status)}>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .sub_status
                          }
                        </div>
                      )}
                  </div>
                  <div className="beneficiary-frame-info ">
                    <div className="benefi-info-card">
                      <h4>{t("customer_remark")}</h4>
                      <p>
                        {singleTransactionView.data.beneficiary_transaction
                          .remarks !== ""
                          ? singleTransactionView.data.beneficiary_transaction
                            .remarks
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="beneficiary-info-card mt-4">
                <div className="beneficiary-info-head">
                  <h3>{t("details")}</h3>
                </div>
                <div className="beneficiary-code-card">
                  <div className="beneficiary-frame-info card-grid__5">
                    <div className="code-frame">
                      <h4>{t("used_id")}</h4>
                      <h5>
                        <span>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.user.id
                          }
                        </span>
                        <CopyToClipboard
                          text={
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.user.id
                          }
                        >
                          <Button
                            className="copy-btn"
                            onClick={() =>
                              getSuccessNotificationMessage("Copied!")
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#171717"
                                d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                                opacity="0.4"
                              ></path>
                            </svg>
                          </Button>
                        </CopyToClipboard>
                      </h5>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("user_type")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.user.type
                        }
                      </p>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("user_country")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.user.country
                        }
                      </p>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("user_currency")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.user.currency
                        }
                      </p>
                    </div>
                  </div>
                  <div className="benefici-space"></div>
                  <div className="beneficiary-frame-info card-grid__5">
                    <div className="code-frame">
                      <h4>{t("receipient_id")}</h4>
                      <h5>
                        <span>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.recipient.id
                          }
                        </span>
                        <CopyToClipboard
                          text={
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.recipient.id
                          }
                        >
                          <Button
                            className="copy-btn"
                            onClick={() =>
                              getSuccessNotificationMessage("Copied!")
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#171717"
                                d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                                opacity="0.4"
                              ></path>
                            </svg>
                          </Button>
                        </CopyToClipboard>
                      </h5>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("receipient_type")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.recipient.type
                        }
                      </p>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("receipient_country")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.recipient.country
                        }
                      </p>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("receipient_currency")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.recipient.currency
                        }
                      </p>
                    </div>
                  </div>
                  <div className="benefici-space"></div>
                  <div className="beneficiary-frame-info">
                    <div className="beneficiary-child-info-head m-0">
                      <h3>{t("receiving_institution")}</h3>
                    </div>
                    <div className="beneficiary-child-frame-info card-grid__4">
                      <div className="benefi-info-card">
                        <h4>{t("type")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.receiving_institution
                              .account_type
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4> {t("name")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.receiving_institution
                              .receiving_institution
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("branch")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.receiving_institution
                              .receiving_institution
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("code")} </h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.receiving_institution
                              .receiving_institution_code
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("account_type")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.receiving_institution
                              .account_type
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("account_name")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.receiving_institution
                              .account_name
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("account_number")} </h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.receiving_institution
                              .account_number
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="beneficiary-info-card mt-4">
                <div className="beneficiary-info-head">
                  <h3> {t("rates_and_fees")}</h3>
                </div>
                <div className="beneficiary-code-card">
                  <div className="beneficiary-child-frame">
                    <div className="beneficiary-child-info-head m-0">
                      <h3> {t("quotes")}</h3>
                    </div>
                    <div className="beneficiary-child-frame-info card-grid__4">
                      <div className="code-frame">
                        <h4>{t("quote_id")}</h4>
                        <h5>
                          <span>
                            {
                              singleTransactionView.data.beneficiary_transaction
                                .beneficiary_transaction.quote.id
                            }
                          </span>
                          <CopyToClipboard
                            text={
                              singleTransactionView.data.beneficiary_transaction
                                .beneficiary_transaction.quote.id
                            }
                          >
                            <Button
                              className="copy-btn"
                              onClick={() =>
                                getSuccessNotificationMessage("Copied!")
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#171717"
                                  d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                                ></path>
                                <path
                                  fill="#171717"
                                  d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                                  opacity="0.4"
                                ></path>
                              </svg>
                            </Button>
                          </CopyToClipboard>
                        </h5>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("quote_type")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.type
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("fee_included")}</h4>
                        <p>
                          {singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction.quote.fees.included ==
                            false
                            ? t("no")
                            : t("yes")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="benefici-space"></div>
                  <div className="beneficiary-child-frame">
                    <div className="beneficiary-child-info-head m-0">
                      <h3>{t("total_fee")}</h3>
                    </div>
                    <div className="beneficiary-child-frame-info card-grid__4">
                      <div className="benefi-info-card">
                        <h4> {t("total")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.total.currency
                          }
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.total.amount
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("fixed")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details.markup
                              .total_markup.fixed_amount.currency
                          }
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details.markup
                              .total_markup.fixed_amount.amount
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4> {t("variable_fee")} %</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details.markup
                              .total_markup.percentage_value
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="benefici-space"></div>
                  <div className="beneficiary-child-frame">
                    <div className="beneficiary-child-info-head m-0">
                      <h3>{t("platform_fee")}</h3>
                    </div>
                    <div className="beneficiary-child-frame-info card-grid__4">
                      <div className="benefi-info-card">
                        <h4> {t("total")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details
                              .platform_charge.total.currency
                          }
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details
                              .platform_charge.total.amount
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("fixed")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details
                              .platform_charge.fixed_amount.currency
                          }
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details
                              .platform_charge.fixed_amount.amount
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("variable_fee")} %</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details
                              .platform_charge.percentage_value
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="benefici-space"></div>
                  <div className="beneficiary-child-frame">
                    <div className="beneficiary-child-info-head m-0">
                      <h3> {t("program_fee")}</h3>
                    </div>
                    <div className="beneficiary-child-frame-info card-grid__4">
                      <div className="benefi-info-card">
                        <h4> {t("total")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details.markup
                              .program.total.currency
                          }
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details.markup
                              .program.total.amount
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("fixed")}</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details.markup
                              .program.fixed_amount.currency
                          }
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details.markup
                              .program.fixed_amount.amount
                          }
                        </p>
                      </div>
                      <div className="benefi-info-card">
                        <h4>{t("variable_fee")} %</h4>
                        <p>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction.quote.fees.details.markup
                              .program.percentage_value
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="beneficiary-info-card mt-4">
                <div className="beneficiary-info-head">
                  <h3> {t("additional_information")}</h3>
                </div>
                <div className="beneficiary-frame-info card-grid__4">
                  <div className="benefi-info-card">
                    <h4>{t("purpose_of_payment")}</h4>
                    <p>
                      {singleTransactionView.data.beneficiary_transaction
                        .beneficiary_transaction.additional_data
                        .purpose_of_payment == ""
                        ? "N/A"
                        : singleTransactionView.data.beneficiary_transaction
                          .beneficiary_transaction.additional_data
                          .purpose_of_payment}
                    </p>
                  </div>
                  <div className="benefi-info-card">
                    <h4>{t("occupation")}</h4>
                    <p>
                      {singleTransactionView.data.beneficiary_transaction
                        .beneficiary_transaction.additional_data
                        .user_occupation == ""
                        ? "N/A"
                        : singleTransactionView.data.beneficiary_transaction
                          .beneficiary_transaction.additional_data
                          .user_occupation}
                    </p>
                  </div>
                  <div className="benefi-info-card">
                    <h4>{t("relationship")}</h4>
                    <p>
                      {singleTransactionView.data.beneficiary_transaction
                        .beneficiary_transaction.additional_data
                        .user_recipient_relationship == ""
                        ? "N/A"
                        : singleTransactionView.data.beneficiary_transaction
                          .beneficiary_transaction.additional_data
                          .user_recipient_relationship}
                    </p>
                  </div>
                  <div className="benefi-info-card">
                    <h4> {t("source_of_income")}</h4>
                    <p>
                      {singleTransactionView.data.beneficiary_transaction
                        .beneficiary_transaction.additional_data
                        .user_source_of_income == ""
                        ? "N/A"
                        : singleTransactionView.data.beneficiary_transaction
                          .beneficiary_transaction.additional_data
                          .user_source_of_income}
                    </p>
                  </div>
                </div>
              </div>
              <div className="beneficiary-info-card mt-4">
                <div className="beneficiary-info-head">
                  <h3> {t("history")}</h3>
                </div>
                <div className="beneficiary-frame-info ">
                  <div className="benefi-info-list">
                    <ul>
                      {singleTransactionView.data.timeline?.history?.SUCCESS
                        ?.length > 0 && (
                          <li>
                            <div className="benefi-info-card">
                              <h4>
                                {
                                  singleTransactionView.data.timeline.history.SUCCESS[0].status
                                }
                              </h4>
                              <p>
                                {singleTransactionView.data.timeline?.history
                                  ?.SUCCESS?.length > 0 &&
                                  singleTransactionView.data.timeline.history
                                    .SUCCESS[0].updated_date}
                              </p>
                            </div>
                          </li>
                        )}
                      {singleTransactionView.data.timeline?.history?.SUBMITTED
                        ?.length > 0 && (
                          <li>
                            <div className="benefi-info-card">
                              <h4>
                                {
                                  singleTransactionView.data.timeline.history
                                    .SUBMITTED[0].status
                                }
                              </h4>
                              <p>
                              {singleTransactionView.data.timeline?.history
                                ?.SUBMITTED?.length > 0 &&
                                singleTransactionView.data.timeline.history
                                  .SUBMITTED[0].sub_status ?
                                singleTransactionView.data.timeline.history
                                  .SUBMITTED[0].sub_status :
                                t("n_a")
                              }
                            </p>
                              <p>
                                {singleTransactionView.data.timeline?.history
                                  ?.SUBMITTED?.length > 0 &&
                                  singleTransactionView.data.timeline.history
                                    .SUBMITTED[0].updated_date}
                              </p>
                            </div>
                          </li>
                        )}
                      {singleTransactionView.data.timeline?.history?.PENDING
                        ?.length > 0 && (
                          <li>
                            <div className="benefi-info-card">
                              <h4>
                                {
                                  singleTransactionView.data.timeline.history
                                    .PENDING[0].status
                                }
                              </h4>
                              <p>
                              {singleTransactionView.data.timeline?.history
                                ?.PENDING?.length > 0 &&
                                singleTransactionView.data.timeline.history
                                  .PENDING[0].sub_status ?
                                singleTransactionView.data.timeline.history
                                  .PENDING[0].sub_status :
                                t("n_a")
                              }
                            </p>
                              <p>
                                {singleTransactionView.data.timeline?.history
                                  ?.PENDING?.length > 0 &&
                                  singleTransactionView.data.timeline.history
                                    .PENDING[0].updated_date}
                              </p>
                            </div>
                          </li>
                        )}
                      {singleTransactionView.data.timeline?.history?.INITIATED
                        ?.length > 0 && (
                          <li>
                            <div className="benefi-info-card">
                              <h4>
                                {
                                  singleTransactionView.data.timeline.history
                                    .INITIATED[0].status
                                }
                              </h4>
                              <p>
                              {singleTransactionView.data.timeline?.history
                                ?.INITIATED?.length > 0 &&
                                singleTransactionView.data.timeline.history
                                  .INITIATED[0].sub_status ?
                                singleTransactionView.data.timeline.history
                                  .INITIATED[0].sub_status :
                                t("n_a")
                              }
                            </p>
                              <p>
                                {singleTransactionView.data.timeline?.history
                                  ?.INITIATED?.length > 0 &&
                                  singleTransactionView.data.timeline.history
                                    .INITIATED[0].updated_date}
                              </p>
                            </div>
                          </li>
                        )}
                      {singleTransactionView.data.timeline?.history?.PROCESSING?.length > 0 && (
                        <li>
                          <div className="benefi-info-card">
                            <h4>
                              {singleTransactionView.data.timeline.history.PROCESSING[0].status}
                            </h4>
                            <p>
                              {singleTransactionView.data.timeline?.history
                                ?.PROCESSING?.length > 0 &&
                                singleTransactionView.data.timeline.history
                                  .PROCESSING[0].sub_status ?
                                singleTransactionView.data.timeline.history
                                  .PROCESSING[0].sub_status :
                                t("n_a")
                              }
                            </p>
                            <p>
                              {singleTransactionView.data.timeline?.history
                                ?.PROCESSING?.length > 0 &&
                                singleTransactionView.data.timeline.history
                                  .PROCESSING[0].updated_date}
                            </p>
                          </div>
                        </li>
                      )}
                      {singleTransactionView.data.timeline?.history?.EXPIRED?.length > 0 && (
                        <li>
                          <div className="benefi-info-card">
                            <h4>
                              {singleTransactionView.data.timeline.history.EXPIRED[0].status}
                            </h4>
                            <p>
                              {singleTransactionView.data.timeline?.history
                                ?.EXPIRED?.length > 0 &&
                                singleTransactionView.data.timeline.history
                                  .EXPIRED[0].sub_status ?
                                singleTransactionView.data.timeline.history
                                  .EXPIRED[0].sub_status :
                                t("n_a")
                              }
                            </p>
                            <p>
                              {singleTransactionView.data.timeline?.history
                                ?.EXPIRED?.length > 0 &&
                                singleTransactionView.data.timeline.history
                                  .EXPIRED[0].updated_date}
                            </p>
                          </div>
                        </li>
                      )}
                         {singleTransactionView.data.timeline?.history?.FAILED?.length > 0 && (
                        <li>
                          <div className="benefi-info-card">
                            <h4>
                              {singleTransactionView.data.timeline.history.FAILED[0].status}
                            </h4>
                            <p>
                              {singleTransactionView.data.timeline?.history
                                ?.FAILED?.length > 0 &&
                                singleTransactionView.data.timeline.history
                                  .FAILED[0].sub_status ?
                                singleTransactionView.data.timeline.history
                                  .FAILED[0].sub_status :
                                t("n_a")
                              }
                            </p>
                            <p>
                              {singleTransactionView.data.timeline?.history
                                ?.FAILED?.length > 0 &&
                                singleTransactionView.data.timeline.history
                                  .FAILED[0].updated_date}
                            </p>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NoDataFoundNew />
          )}
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryView;
