import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import Select from "react-select";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useTranslation } from "react-multi-lang";
import countryList from "react-select-country-list";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import {
  bankListStart,
  countryListStart,
  createBeneficiaryStart,
} from "../../store/slices/BeneficiariesSlice";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import { Radio } from "pretty-checkbox-react";
import { AsyncPaginate } from "react-select-async-paginate";
import api from "../../Environment";
import { getErrorNotificationMessage } from "../../components/Helper/NotificationMessage";
import { ButtonLoader } from "../Helper/Loader";

const CreateBeneficary = () => {
  const selectRef = useRef();
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [take, setTake] = useState(100);
  const [skip, setSkip] = useState(0);
  const [purpose, setPurpose] = useState("");
  const t = useTranslation("beneficiaries.create_beneficiaries");
  const dispatch = useDispatch();
  const countriesLists = useSelector(
    (state) => state.beneficiaries.countryList
  );
  const createBeneficiary = useSelector(
    (state) => state.beneficiaries.createBeneficiary
  );
  const formRef = useRef();
  const [searchValue, setSearchValue] = useState("null");
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedBankCountry, setSelectedBankCountry] = useState(null);
  const [selectedRecivingCurrency, setSelectedRecivingCurrency] =
    useState(null);
  const [selectedRecivingCountry, setSelectedRecivingCountry] = useState(null);
  const [currencyList, setCurrencyList] = useState(null);
  const [skipRender, setSkipRender] = useState(true);
  const [banks, setBanks] = useState(true);
  const [bankList, setBankList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const navigate = useNavigate();
  const AccountType = [
    { value: "current", label: "Current Account" },
    { value: "savings", label: "Savings Account" },
  ];
  const [selectedDate, setSelectedDate] = useState(null);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t("required")),
    last_name: Yup.string().required(t("required")),
    receiving_currency: Yup.string().required(t("required")),
    recipient_country: Yup.string().required(t("required")),
    email: Yup.string().email(t("email.invalid")).required(t("required")),
    state: Yup.string().required(t("required")),
    city: Yup.string().required(t("required")),
    country: Yup.string().required(t("required")),
    code: Yup.string().required(t("required")),
    primary_address: Yup.string().required(t("required")),
    secondary_address: Yup.string().required(t("required")),
    service_bank_id: Yup.string().required(t("required")),
    service_bank_country: Yup.string().required(t("required")),
    account_name: Yup.string().required(t("required")),
    account_number: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[0-9])(?=.{9,})/, t("account_number.invalid")),
    account_confirmation: Yup.string()
      .required(t(".required"))
      .oneOf([Yup.ref("account_number")], t("account.invalid")),
    account_type: Yup.string().required(t("required")),
    purpose_of_payment: Yup.string().required(t("required")),
    mobile: Yup.string()
      .test("valid-mobile", t("mobile.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(t("required"))
      .matches(/^(?=.*[0-9])/, t("mobile_number.space_values_not_valid")),
    dob: Yup.string().when("receiving_currency", {
      is: "BDT",
      then: Yup.string()
        .required(t("required"))
        .matches(/^\d{4}-\d{2}-\d{2}$/, t("dob.invalid_date_format"))
        .test("is-past-date", "date cannot be future", (value) => {
          const dob = new Date(value);
          const currentDate = new Date();
          return dob <= currentDate;
        })
        .test("is-18-years-old", "must be 18 years old", (value) => {
          const dob = new Date(value);
          const currentDate = new Date();
          const age = currentDate.getFullYear() - dob.getFullYear();
          const monthDiff = currentDate.getMonth() - dob.getMonth();
          return (
            age > 18 ||
            (age === 18 && monthDiff > 0) ||
            (age === 18 &&
              monthDiff === 0 &&
              currentDate.getDate() >= dob.getDate())
          );
        }),
    }),
    user_source_of_income: Yup.string().when("recipient_country", {
      is: "PHL",
      then: Yup.string().required(t("required")),
    }),
    user_occupation: Yup.string().when("recipient_country", {
      is: "PHL",
      then: Yup.string().required(t("required")),
    }),
    user_recipient_relationship: Yup.string().when("recipient_country", {
      is: "PHL",
      then: Yup.string().required(t("required")),
    }),
    postal_code: Yup.string()
      .required(t("required"))
      .matches(/^\d{6}$/, "Six digit only allowed"),
  });
  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile_number.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      const { service_bank_country, ...rest } = values;
      dispatch(
        createBeneficiaryStart({
          ...rest,
          mobile: mobile,
          mobile_country_code: countryCode,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(countryListStart());
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !countriesLists.loading &&
      Object.keys(countriesLists.data).length > 0
    ) {
      setCountryCodeList(
        countriesLists.data.service_countries.map((country) => ({
          label: `${country.destination_country} (${country.destination_country_code})`,
          value: country.destination_country_code,
        }))
      );
      setCountryList(
        countriesLists.data.service_countries.map((country) => ({
          label: `${country.destination_country} (${country.destination_country_code})`,
          value: country.destination_country_code,
        }))
      );
      const uniqueCurrencies = new Set(
        countriesLists.data.service_countries.map(
          (data) => data.destination_currencies[0]
        )
      );
      const currencyList = Array.from(uniqueCurrencies).map((currency) => ({
        label: currency,
        value: currency,
      }));

      setCurrencyList(currencyList);
    }
  }, [countriesLists]);

  // useEffect(() => {
  //   if (!skipRender) {
  //     dispatch(bankListStart({}));
  //   }
  //   setSkipRender(false);
  // }, [countryCodeList]);

  const getCountriesByCurrency = (data) => {
    if (data?.value == "PHP") {
      const { dob, ...rest } = formRef.current.values;
      formRef.current.setValues({
        ...rest,
        user_source_of_income: "",
        user_occupation: "",
        user_recipient_relationship: "",
      });
    } else if (data?.value == "BDT") {
      formRef.current.setValues({ ...formRef.current.values, dob: "" });
    } else {
      const {
        user_source_of_income,
        user_occupation,
        user_recipient_relationship,
        dob,
        ...rest
      } = formRef.current.values;
      formRef.current.setValues({ ...rest });
    }

    if (data) {
      formRef.current.setFieldValue("receiving_currency", data.value);
      setSelectedRecivingCurrency(data);
      // setCountryCodeList(
      //   countriesLists.data.service_countries
      //     .filter((country) => country.destination_currencies[0] == data.value)
      //     .map((country) => ({
      //       label: `${country.destination_country} (${country.destination_country_code})`,
      //       value: country.destination_country_code,
      //     }))
      // );
    } else {
      // setCountryCodeList(
      //   countriesLists.data.service_countries.map((country) => ({
      //     label: `${country.destination_country} (${country.destination_country_code})`,
      //     value: country.destination_country_code,
      //   }))
      // );
      formRef.current.setFieldValue("receiving_currency", "");
      setSelectedRecivingCurrency(null);
    }
  };

  const getCurrenciesByCountry = (data) => {
    if (data) {
      if (data.value !== selectedRecivingCountry?.value) {
        setSelectedRecivingCurrency(null);
        formRef.current.setFieldValue("receiving_currency", "");
      }

      formRef.current.setFieldValue("recipient_country", data.value);
      setSelectedRecivingCountry(data);
      setCurrencyList(
        countriesLists.data.service_countries
          .filter((country) => country.destination_country_code == data.value)
          .map((data) => ({
            label: data.destination_currencies[0],
            value: data.destination_currencies[0],
          }))
      );
    } else {
      formRef.current.setFieldValue("recipient_country", "");
      setSelectedRecivingCountry(null);
      setCurrencyList(
        countriesLists.data.service_countries.map((data) => ({
          label: data.destination_currencies[0],
          value: data.destination_currencies[0],
        }))
      );
    }
  };

  // useEffect(() => {
  //   if (
  //     Object.keys(bankList.data).length > 0 &&
  //     !bankList.loading &&
  //     bankList.data.total_service_banks > 0
  //   ) {
  //     setBanks(
  //       bankList.data.service_banks.map((bank) => ({
  //         label: bank.bank_name,
  //         value: bank.id,
  //       }))
  //     );
  //   }
  // }, [bankList]);

  const handleDateChange = (values) => {
    formRef.current.setFieldValue(
      "dob",
      values !== null ? JSON.stringify(new Date(values)).slice(1, 11) : ""
    );
    setSelectedDate(values);
  };

  useEffect(() => {
    if (
      !skipRender &&
      Object.keys(createBeneficiary.data).length > 0 &&
      !createBeneficiary.loading
    ) {
      navigate("/beneficiary");
    }
    setSkipRender(false);
  }, [createBeneficiary]);

  const handleBankListChange = (data) => {
    formRef.current.setFieldValue("service_bank_country", data.value);
    setBanks(false);
    setSelectedBankCountry(data);
    setSkip(0);
    setTake(100);
    setBankList([]);
    // dispatch(bankListStart({ country_code: data.value }));
    setTimeout(() => {
      setBanks(true);
    }, 0);
  };

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid  #DDE1E6 !important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878E96",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      color: "#000",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const purposeOfPayment = [
    { label: "Family Maintenance", value: "Family Maintenance" },
    { label: "Household Maintenance", value: "Household Maintenance" },
    { label: "Donation or Gifts", value: "Donation or Gifts" },
    { label: "Payment of Loan", value: "Payment of Loan" },
    { label: "Purchase of Property", value: "Purchase of Property" },
    { label: "Funeral Expenses", value: "Funeral Expenses" },
    { label: "Wedding Expenses", value: "Wedding Expenses" },
    { label: "Medical Expenses", value: "Medical Expenses" },
    { label: "Payment of bills", value: "Payment of bills" },
    { label: "Education", value: "Education" },
    { label: "Savings", value: "Savings" },
    { label: "Employee Colleague", value: "Employee Colleague" },
  ];

  const loadOptions = async (search) => {
    if (search == searchValue) {
      setSkip(skip + take);
    } else {
      setSkip(0);
      setTake(100);
    }
    setSearchValue(search == "" ? "" : search);

    const payload = {
      search_key: search,
      country_code: selectedBankCountry?.value
        ? selectedBankCountry?.value
        : "",
      skip: search == searchValue ? skip + take : 0,
      take: take,
    };

    try {
      if (selectedBankCountry?.value == null) {
        return {
          options: [{ label: "Select Bank Country", value: "" }],
          hasMore: false,
        };
      }
      const response = await api.postMethod("iban/service_banks", payload);
      if (response.data.success) {
        if (response.data.data.total_service_banks > 0) {
          const newBanks = response.data.data.service_banks.map((bank) => ({
            label: bank.bank_name,
            value: bank.id,
          }));
          setBankList([...bankList, ...newBanks]);
          const uniqueNewBanks = bankList.filter(
            (existingBank) =>
              !newBanks.some((newBank) => existingBank.value === newBank.value)
          );
          return {
            options: newBanks,
            hasMore:
              uniqueNewBanks.length < response.data.data.total_service_banks,
          };
        } else {
          return {
            options: [{ label: "No data available", value: "" }],
            hasMore: false,
          };
        }
      } else {
        return {
          options: [{ label: "No data available", value: "" }],
          hasMore: false,
        };
      }
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <div className="create-beneficiary-from-back">
            <Link onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                data-name="Layer 2"
                viewBox="0 0 24 24"
              >
                <path d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"></path>
              </svg>
            </Link>
            <h2>{t("heading")}</h2>
          </div>
          <div className="quote-space"></div>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              middle_name: "",
              email: "",
              mobile: "",
              account_number: "",
              account_type: "",
              account_confirmation: "",
              receiving_currency: "",
              recipient_country: "",
              primary_address: "",
              secondary_address: "",
              city: "",
              state: "",
              postal_code: "",
              code: "",
              country: "",
              dob: "",
              service_bank_id: "",
              account_name: "",
              purpose_of_payment: "",
              service_bank_country: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ errors, touched, values, setFieldValue, setValues }) => (
              <FORM className="create-beneficiary-from">
                <Row>
                  <Col md={12} lg={6} xl={6}>
                    <div className="beneficiary-from-box">
                      <div className="beneficiary-from-heading">
                        <h3>{t("personal_details")}</h3>
                      </div>
                      <Row>
                        <Col sm={12} lg={12} xl={4}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("first_name.name")} <span>*</span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="first_name"
                              placeholder={t("first_name.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="first_name"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={4}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>{t("middle_name.name")}</Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="middle_name"
                              placeholder={t("middle_name.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="middle_name"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={4}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("last_name.name")} <span>*</span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="last_name"
                              placeholder={t("last_name.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="last_name"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={12} xl={6}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              {t("email.name")} <span>*</span>
                            </Form.Label>
                            <Field
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder={t("email.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="email"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>
                              {t("mobile_number.name")} <span>*</span>
                            </Form.Label>
                            <PhoneInput
                              defaultCountry="IN"
                              placeholder={t("mobile_number.placeholder")}
                              value={values.mobile}
                              onChange={(value) =>
                                setFieldValue("mobile", value)
                              }
                              onBlur={() => formRef.current.submitForm()}
                              international
                              countryCallingCodeEditable={false}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="mobile"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={12} className="mt-3">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              {t("country")} <span>*</span>
                            </Form.Label>
                            <Select
                              options={countryList}
                              value={country}
                              styles={customStyles}
                              onChange={(data) => {
                                setCountry(data);

                                setFieldValue("country", data.value);
                              }}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="country"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <div className="quote-space"></div>
                    <div className="recipient-from-box">
                      <div className="beneficiary-from-heading">
                        <h3>{t("bank_details")}</h3>
                      </div>
                      <Row>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("account_type.name")} <span>*</span>
                            </Form.Label>
                            <Select
                              value={selectedAccountType}
                              className="fillert-drop"
                              options={AccountType}
                              onChange={(account) => {
                                {
                                  setSelectedAccountType(account);
                                  account !== null
                                    ? setFieldValue(
                                        "account_type",
                                        account.value
                                      )
                                    : setFieldValue("account_type", "");
                                }
                              }}
                              isClearable
                              styles={customStyles}
                              placeholder={t("account_type.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="account_type"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {selectedRecivingCurrency?.value == "EUR" ||
                              selectedRecivingCurrency?.value == "GBP"
                                ? t("iban_code.name")
                                : t("ifsc_code.name")}
                              <span>*</span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="code"
                              placeholder={
                                selectedRecivingCurrency?.value == "EUR" ||
                                selectedRecivingCurrency?.value == "GBP"
                                  ? t("iban_code.placeholder")
                                  : t("ifsc_code.placeholder")
                              }
                            />
                            <ErrorMessage
                              component={"div"}
                              name="code"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("account_number.name")} <span>*</span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="account_number"
                              placeholder={t("account_number.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="account_number"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("re_account_number.name")} <span>*</span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="account_confirmation"
                              placeholder={t("re_account_number.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="account_confirmation"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={12}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("account_name.name")}
                              <span>*</span>
                            </Form.Label>
                            <Field
                              className="form-control"
                              name="account_name"
                              type="text"
                              placeholder={t("account_name.placeholder")}
                              rows={3}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="account_name"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              {t("bank_country")}
                              <span>*</span>
                            </Form.Label>
                            <Select
                              options={countryCodeList}
                              value={selectedBankCountry}
                              styles={customStyles}
                              onChange={(data) => {
                                {
                                  handleBankListChange(data);
                                }
                              }}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="service_bank_country"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              {t("select_bank.name")} <span>*</span>
                            </Form.Label>
                            {/* <Select
                              className="fillert-drop"
                              options={banks}
                              isClearable
                              styles={customStyles}
                              placeholder="Select Bank Name"
                              onChange={(data) => {
                                {
                                  data !== null &&
                                    setFieldValue(
                                      "service_bank_id",
                                      data.value
                                    );
                                }
                              }}
                            /> */}
                            {banks && (
                              <AsyncPaginate
                                isClearable
                                isSearchable={true}
                                placeholder={t("select_bank.placeholder")}
                                debounceTimeout={500}
                                loadOptions={loadOptions}
                                styles={customStyles}
                                onChange={(data) => {
                                  {
                                    data != null
                                      ? setFieldValue(
                                          "service_bank_id",
                                          data.value
                                        )
                                      : setFieldValue("service_bank_id", "");
                                  }
                                }}
                              />
                            )}

                            <ErrorMessage
                              component={"div"}
                              name="service_bank_id"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} lg={6} xl={6}>
                    <div className="recipient-from-box">
                      <div className="beneficiary-from-heading">
                        <h3>{t("region_details")}</h3>
                      </div>
                      <Row>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              {t("receiving_country.name")} <span>*</span>
                            </Form.Label>
                            <Select
                              options={countryCodeList}
                              value={selectedRecivingCountry}
                              isClearable
                              styles={customStyles}
                              onChange={(data) => {
                                getCurrenciesByCountry(data);
                              }}
                              placeholder={t("receiving_country.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="recipient_country"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              {t("receiving_currency.name")} <span>*</span>
                            </Form.Label>
                            <Select
                              className="fillert-drop"
                              value={selectedRecivingCurrency}
                              options={currencyList}
                              isClearable
                              styles={customStyles}
                              onChange={(data) => {
                                getCountriesByCurrency(data);
                              }}
                              placeholder={t("receiving_currency.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="receiving_currency"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        {selectedRecivingCurrency?.value == "BDT" && (
                          <Col sm={12} lg={12} xl={12}>
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label className="w-100">
                                {t("date_of_birth.name")} <span>*</span>
                              </Form.Label>
                              <DatePicker
                                value={selectedDate}
                                maxDate={
                                  new Date(new Date().setHours(23, 59, 59, 999))
                                }
                                 format="DD MMMM YYYY"
                                onChange={handleDateChange}
                                multiple={false}
                                placeholder={t("date_of_birth.placeholder")}
                                className="w-100"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="dob"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                    </div>
                    <div className="quote-space"></div>
                    <div className="recipient-from-box ">
                      <div className="beneficiary-from-heading">
                        <h3>{t("address_details")}</h3>
                      </div>
                      <Row>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("address_line.name")} 1 <span>*</span>
                            </Form.Label>
                            <Field
                              className="form-control"
                              name="primary_address"
                              as="textarea"
                              placeholder={
                                `${t("address_line.placeholder")}` + "1"
                              }
                              rows={3}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="primary_address"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("address_line.name")} 2 <span>*</span>
                            </Form.Label>
                            <Field
                              className="form-control"
                              name="secondary_address"
                              as="textarea"
                              placeholder={
                                `${t("address_line.placeholder")}` + "2"
                              }
                              rows={3}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="secondary_address"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("state.name")} <span>*</span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="state"
                              placeholder={t("state.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="state"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={6}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3"
                          >
                            <Form.Label>
                              {t("city.name")} <span>*</span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="city"
                              placeholder={t("city.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="city"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={12}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              {t("zip_code.name")}
                              <span>*</span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="postal_code"
                              placeholder={t("zip_code.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="postal_code"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} lg={12} xl={12} className="mt-3">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              {t("purpose_of_payment")} <span>*</span>
                            </Form.Label>
                            <Select
                              options={purposeOfPayment}
                              value={purpose}
                              styles={customStyles}
                              onChange={(data) => {
                                setPurpose(data);

                                setFieldValue("purpose_of_payment", data.value);
                              }}
                              placeholder="Select Purpose of Payment"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="purpose_of_payment"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                        {selectedRecivingCurrency?.value == "PHP" && (
                          <Row>
                            <Col sm={12} lg={12} xl={4}>
                              <Form.Group
                                controlId="formBasicEmail"
                                className="mb-3"
                              >
                                <Form.Label>
                                  {t("source_of_income.name")} <span>*</span>
                                </Form.Label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="user_source_of_income"
                                  placeholder={t(
                                    "source_of_income.placeholder"
                                  )}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="user_source_of_income"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                            <Col sm={12} lg={12} xl={4}>
                              <Form.Group
                                controlId="formBasicEmail"
                                className="mb-3"
                              >
                                <Form.Label>
                                  {t("occupation.name")} <span>*</span>
                                </Form.Label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="user_occupation"
                                  placeholder={t("occupation.placeholder")}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="user_occupation"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                            <Col sm={12} lg={12} xl={4}>
                              <Form.Group
                                controlId="formBasicEmail"
                                className="mb-3"
                              >
                                <Form.Label>
                                  {t("beneficiary_relationship.name")}{" "}
                                  <span>*</span>
                                </Form.Label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="user_recipient_relationship"
                                  placeholder={t(
                                    "beneficiary_relationship.placeholder"
                                  )}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="user_recipient_relationship"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        )}

                        <Col md={12} className="mt-3">
                          <Form.Group className="mb-3">
                            <Radio
                              name="radio"
                              onClick={(e) =>
                                setFieldValue("recipient_type", e.target.value)
                              }
                              value="1"
                            >
                              {t("individual")}
                            </Radio>
                            <Radio
                              name="radio"
                              onClick={(e) =>
                                setFieldValue("recipient_type", e.target.value)
                              }
                              value="2"
                            >
                              {t("business")}
                            </Radio>
                            <ErrorMessage
                              component={"div"}
                              name="recipient_type"
                              className="errorMsg"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} lg={12}>
                    <div className="create-beneficiary-btn-sec">
                      <Button
                        className="efi-overlay-btn"
                        onClick={() => navigate("/beneficiary")}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        className="efi-primary-btn"
                        type="submit"
                        disabled={createBeneficiary.buttonDisable}
                      >
                        {createBeneficiary.buttonDisable ? (
                          <ButtonLoader />
                        ) : (
                          t("create")
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </FORM>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default CreateBeneficary;
