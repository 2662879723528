import React, { useState } from "react";
import { toggleSidebar } from "../../../store/slices/CommonSlice";
import { Image, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = (props) => {
  const location = useLocation();
  const profile = useSelector((state) => state.admin.profile);

  const dispatch = useDispatch();

  return (
    <>
      <div className={`admin-ui-sidebar`}>
        {/* <div className="admin-collapse-btn">
          <Button className="collapse-close-btn"  onMouseOver={() => dispatch(toggleSidebar())}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              data-name="Layer 1"
              viewBox="0 0 512 512"
            >
              <path d="M121.373 457.373L322.745 256 121.373 54.627a32 32 0 0145.254-45.254l224 224a32 32 0 010 45.254l-224 224a32 32 0 01-45.254-45.254z"></path>
            </svg>
          </Button>
        </div> */}
        <div className="admin-ui-sidebar-collapse">
          <div className="admin-ui-collapse-logo-sec">
            <Link className="" to="/">
              <Image
                className="admin-ui-logo"
                src={window.location.origin + "/img/efi-bank-fav-icon.png"}
              />
            </Link>
          </div>
          <div className="admin-ui-collapse-body">
            <ul className="admin-ui-sidebar-list list-unstyled">
              {/* <li>
                <Link to="/dashboard"
                  className={`${location.pathname.includes(
                    "dashboard"
                  ) && "active"
                    }`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#656565"
                      d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2h-4.04c-1.59 0-2.23.57-2.23 1.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04c1.59.01 2.23-.56 2.23-1.97z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M22 19.77v-4.04c0-1.59-.64-2.23-2.23-2.23h-4.04c-1.59 0-2.23.64-2.23 2.23v4.04c0 1.59.64 2.23 2.23 2.23h4.04c1.59 0 2.23-.64 2.23-2.23zM10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04c1.59.01 2.23-.56 2.23-1.97z"
                    ></path>
                    <path
                      fill="#656565"
                      d="M10.5 19.77v-4.04c0-1.59-.64-2.23-2.23-2.23H4.23c-1.59 0-2.23.64-2.23 2.23v4.04C2 21.36 2.64 22 4.23 22h4.04c1.59 0 2.23-.64 2.23-2.23z"
                      opacity="0.4"
                    ></path>
                  </svg>
                </Link>
              </li> */}
              <li>
                <Link
                  to="/account"
                  className={`${location.pathname == "/account" && "active"}`}
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-building-bank"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 21l18 0" /><path d="M3 10l18 0" /><path d="M5 6l7 -3l7 3" /><path d="M4 10l0 11" /><path d="M20 10l0 11" /><path d="M8 14l0 3" /><path d="M12 14l0 3" /><path d="M16 14l0 3" /></svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#656565"
                      stroke="#656565"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      strokeWidth="1.5"
                      d="M22 19v3H2v-3c0-.55.45-1 1-1h18c.55 0 1 .45 1 1z"
                    ></path>
                    <path
                      fill="#656565"
                      d="M8 11H4v7h4v-7z"
                      opacity="0.6"
                    ></path>
                    <path
                      fill="#656565"
                      d="M12 11H8v7h4v-7z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M16 11h-4v7h4v-7z"
                      opacity="0.6"
                    ></path>
                    <path
                      fill="#656565"
                      d="M20 11h-4v7h4v-7z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M23 22.75H1c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h22c.41 0 .75.34.75.75s-.34.75-.75.75zM21.37 5.75l-9-3.6c-.2-.08-.54-.08-.74 0l-9 3.6c-.35.14-.63.55-.63.93V10c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V6.68c0-.38-.28-.79-.63-.93zM12 8.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
                    ></path>
                  </svg>
                </Link>
              </li>
              {/* {profile.data.user_type == 2 ? (
                <li>
                  <Link
                    to="/virtual-account"
                    className={`my-first-step ${
                      location.pathname.includes("virtual-account") && "active"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#656565"
                        d="M10.49 2.23L5.5 4.1c-1.15.43-2.09 1.79-2.09 3.02v7.43c0 1.18.78 2.73 1.73 3.44l4.3 3.21c1.41 1.06 3.73 1.06 5.14 0l4.3-3.21c.95-.71 1.73-2.26 1.73-3.44V7.12c0-1.23-.94-2.59-2.09-3.02l-4.99-1.87c-.85-.31-2.21-.31-3.04 0z"
                        opacity="0.4"
                      ></path>
                      <path
                        fill="#656565"
                        d="M11.9 11.3h.1c1.15-.04 2.05-.97 2.06-2.13 0-1.18-.96-2.14-2.14-2.14-1.18 0-2.14.96-2.14 2.14.02 1.15.92 2.09 2.12 2.13zM14.19 13.41c-1.21-.8-3.17-.8-4.39 0-.56.38-.89.92-.89 1.48 0 .57.32 1.09.89 1.47.61.4 1.4.61 2.19.61s1.59-.2 2.19-.61c.56-.38.89-.92.89-1.48.01-.57-.31-1.09-.88-1.47z"
                      ></path>
                    </svg>
                  </Link>
                </li>
              ) : null} */}
              {/* {profile.data.mastercard_service == 1 ? (
                <li>
                  <Link
                    to="/card-details"
                    className={`${
                      location.pathname.includes("card-details") && "active"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="none"
                      viewBox="0 0 25 24"
                    >
                      <path
                        fill="#656565"
                        d="M22.03 7.54V9h-20V7.54c0-2.29 1.86-4.14 4.15-4.14h11.7c2.29 0 4.15 1.85 4.15 4.14z"
                      ></path>
                      <path
                        fill="#656565"
                        fillRule="evenodd"
                        d="M22.03 9v7.46c0 2.29-1.86 4.14-4.15 4.14H6.18c-2.29 0-4.15-1.85-4.15-4.14V9h20zm-14 8.25h-2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h2c.41 0 .75.34.75.75s-.34.75-.75.75zm2.5 0h4c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-4c-.41 0-.75.34-.75.75s.34.75.75.75z"
                        clipRule="evenodd"
                        opacity="0.4"
                      ></path>
                    </svg>
                  </Link>
                </li>
              ) : null}
              <li>
                <Link
                  to="/transactions"
                  className={`${
                    location.pathname == "/transactions" && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="32"
                    fill="none"
                    viewBox="0 0 24 32"
                  >
                    <g fill="#656565">
                      <path d="M7.81.318a1.044 1.044 0 01.738.304l6.23 6.25a1.037 1.037 0 01.3.738c.001.135-.024.269-.074.394a1.04 1.04 0 01-.97.641l-3.125-.009-.028 9.347a1.037 1.037 0 01-1.045 1.035l-4.166-.012a1.043 1.043 0 01-1.038-1.042l.028-9.347-3.124-.01a1.044 1.044 0 01-.958-.647 1.036 1.036 0 01.234-1.13L7.08.617c.194-.19.456-.298.73-.299z"></path>
                      <path
                        d="M12.976 13.835l-.028 9.347-3.124-.01a1.044 1.044 0 00-.745 1.774l6.23 6.25a1.044 1.044 0 00.739.303 1.044 1.044 0 00.74-.299l6.268-6.212a1.036 1.036 0 00.304-.736.87.87 0 00-.082-.395 1.04 1.04 0 00-.956-.647l-3.125-.01.028-9.346a1.037 1.037 0 00-1.038-1.042l-4.166-.012a1.043 1.043 0 00-1.045 1.035z"
                        opacity="0.4"
                      ></path>
                    </g>
                  </svg>
                </Link>
              </li> */}
              {/* <li>
                <Link to="/user-access"
                  className={`${location.pathname.includes(
                    "user-access"
                  ) && "active"
                    }`}>
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-address-book"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z" /><path d="M10 16h6" /><path d="M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M4 8h3" /><path d="M4 12h3" /><path d="M4 16h3" /></svg>
                </Link>
              </li> */}
              {/* <li>
                <Link
                  to="/recipients"
                  className={`${
                    location.pathname.includes("recipients") && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#656565"
                      d="M9 2C6.38 2 4.25 4.13 4.25 6.75c0 2.57 2.01 4.65 4.63 4.74.08-.01.16-.01.22 0h.07a4.738 4.738 0 004.58-4.74C13.75 4.13 11.62 2 9 2z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M14.08 14.15c-2.79-1.86-7.34-1.86-10.15 0-1.27.85-1.97 2-1.97 3.23s.7 2.37 1.96 3.21C5.32 21.53 7.16 22 9 22c1.84 0 3.68-.47 5.08-1.41 1.26-.85 1.96-1.99 1.96-3.23-.01-1.23-.7-2.37-1.96-3.21z"
                    ></path>
                    <path
                      fill="#656565"
                      d="M19.99 7.34c.16 1.94-1.22 3.64-3.13 3.87h-.05c-.06 0-.12 0-.17.02-.97.05-1.86-.26-2.53-.83 1.03-.92 1.62-2.3 1.5-3.8a4.64 4.64 0 00-.77-2.18 3.592 3.592 0 015.15 2.92z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M21.99 16.59c-.08.97-.7 1.81-1.74 2.38-1 .55-2.26.81-3.51.78.72-.65 1.14-1.46 1.22-2.32.1-1.24-.49-2.43-1.67-3.38-.67-.53-1.45-.95-2.3-1.26 2.21-.64 4.99-.21 6.7 1.17.92.74 1.39 1.67 1.3 2.63z"
                    ></path>
                  </svg>
                </Link>
              </li> */}
              <li>
                <Link
                  to="/beneficiary"
                  className={`${
                    location.pathname == "/beneficiary" && "active"
                  }`}
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-invoice"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M19 12v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-14a2 2 0 0 1 2 -2h7l5 5v4.25" /></svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 64 64"
                  >
                    <g fill="#656565">
                      <path
                        d="M27.609 45.664a8.061 8.061 0 104.39-14.823 8.1 8.1 0 00-4.39 14.823zm15.272-5.257c.276 8.487 12.584 8.486 12.868 0-.303-8.513-12.564-8.514-12.868 0z"
                        data-original="#656565"
                      ></path>
                      <path
                        d="M55.13 46.492a8.446 8.446 0 01-10.862.649c-.694-.645-.832-.742-1.576-.07 2.61 2.477 5.201 6.031 4.708 9.795h9.008a3.536 3.536 0 002.872-1.486c2.101-3.111-1.652-7.298-4.15-8.888zm-13.935 1.915a13.38 13.38 0 00-2.693-1.835 11.468 11.468 0 01-1.027.768 10.104 10.104 0 01-10.943 0 13.28 13.28 0 01-1.038-.768 13.381 13.381 0 00-2.693 1.835 13.815 13.815 0 00-4.08 6.045A4.262 4.262 0 0022.771 60h18.454a4.334 4.334 0 004.13-3.133c.638-3.175-1.984-6.446-4.16-8.459zm-19.89-1.347c-.729-.66-.906-.551-1.576.08a8.447 8.447 0 01-10.864-.648c-2.487 1.584-6.243 5.788-4.15 8.888a3.536 3.536 0 002.874 1.486h9.007c-.503-3.745 2.097-7.314 4.709-9.805z"
                        data-original="#656565"
                      ></path>
                      <path
                        d="M21.115 40.407c-.303-8.513-12.564-8.514-12.868 0 .282 8.483 12.59 8.49 12.868 0z"
                        data-original="#656565"
                      ></path>
                      <path
                        d="M13.883 32.467a1.005 1.005 0 001.596 0l2.993-3.99a.998.998 0 00-1.596-1.197l-1.197 1.596V17.664a.994.994 0 01.997-.998h3.382c1.38 14.867 22.506 14.856 23.88 0h3.382a.994.994 0 01.998.998v11.212L47.12 27.28a.998.998 0 00-1.596 1.197l2.992 3.99a1.005 1.005 0 001.596 0l2.993-3.99a.998.998 0 00-1.596-1.197l-1.197 1.596V17.664a2.995 2.995 0 00-2.993-2.993H43.91c-1.845-14.226-21.975-14.23-23.821 0h-3.412a2.995 2.995 0 00-2.992 2.993v11.212l-1.197-1.596a.998.998 0 00-1.596 1.197zm18.115-15.561a3.69 3.69 0 01-.997-7.232V7.818a.998.998 0 011.995 0v1.856a3.675 3.675 0 012.693 3.54.998.998 0 01-1.995 0 1.696 1.696 0 10-1.696 1.697 3.695 3.695 0 01.998 7.242v1.845a.998.998 0 01-1.995 0v-1.845a3.695 3.695 0 01-2.694-3.552.998.998 0 011.995 0 1.696 1.696 0 101.696-1.695z"
                        data-original="#656565"
                      ></path>
                    </g>
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="/quote"
                  className={`${
                    location.pathname.includes("quote") && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#656565"
                      d="M22 8.5a6.5 6.5 0 01-7.02 6.48 6.509 6.509 0 00-5.96-5.96A6.5 6.5 0 1122 8.5z"
                    ></path>
                    <path
                      fill="#656565"
                      d="M15 15.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M7.62 14.62L8.5 13l.88 1.62 1.62.88-1.62.88L8.5 18l-.88-1.62L6 15.5l1.62-.88z"
                    ></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="/beneficiary-transactions"
                  className={`${
                    location.pathname == "/beneficiary-transactions" && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#171717"
                      d="M12 15.7v.61H2v-.61c0-1.76.44-2.2 2.22-2.2h5.56c1.78 0 2.22.44 2.22 2.2z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#171717"
                      d="M2 16.31v3.49c0 1.76.44 2.2 2.22 2.2h5.56c1.78 0 2.22-.44 2.22-2.2v-3.49H2z"
                    ></path>
                    <g
                      fill="#171717"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      opacity="0.4"
                    >
                      <path d="M22 14.25a.75.75 0 01.75.75A7.745 7.745 0 0115 22.75a.75.75 0 01-.643-1.136l1.05-1.75a.75.75 0 111.286.772l-.271.452A6.246 6.246 0 0021.25 15a.75.75 0 01.75-.75zM7.578 2.912A6.246 6.246 0 002.75 9a.75.75 0 01-1.5 0A7.745 7.745 0 019 1.25a.75.75 0 01.643 1.136l-1.05 1.75a.75.75 0 01-1.286-.772l.271-.452z"></path>
                    </g>
                    <path
                      fill="#171717"
                      d="M23 6.5h-9c0 2.48 2.02 4.5 4.5 4.5A4.5 4.5 0 0023 6.5z"
                      opacity="0.4"
                    ></path>
                    <path fill="#171717" d="M23 6.5a4.5 4.5 0 00-9 0h9z"></path>
                  </svg>
                </Link>
              </li>
              {/* {profile.data.user_type == 2 ? (
                <li>
                  <Link
                    to="/business-user-list"
                    className={`${
                      location.pathname.includes("business-user-list") &&
                      "active"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#656565"
                        d="M17.53 7.77a.739.739 0 00-.21 0 2.874 2.874 0 01-2.78-2.88C14.54 3.3 15.83 2 17.43 2c1.59 0 2.89 1.29 2.89 2.89a2.89 2.89 0 01-2.79 2.88zM20.79 14.7c-1.12.75-2.69 1.03-4.14.84.38-.82.58-1.73.59-2.69 0-1-.22-1.95-.64-2.78 1.48-.2 3.05.08 4.18.83 1.58 1.04 1.58 2.75.01 3.8zM6.44 7.77c.07-.01.14-.01.21 0a2.874 2.874 0 002.78-2.88C9.43 3.3 8.14 2 6.54 2 4.95 2 3.65 3.29 3.65 4.89a2.89 2.89 0 002.79 2.88zM6.55 12.85c0 .97.21 1.89.59 2.72-1.41.15-2.88-.15-3.96-.86-1.58-1.05-1.58-2.76 0-3.81 1.07-.72 2.58-1.01 4-.85-.41.84-.63 1.79-.63 2.8z"
                        opacity="0.4"
                      ></path>
                      <path
                        fill="#656565"
                        d="M12.12 15.87c-.08-.01-.17-.01-.26 0a3.425 3.425 0 01-3.31-3.43c0-1.9 1.53-3.44 3.44-3.44 1.9 0 3.44 1.54 3.44 3.44 0 1.86-1.46 3.37-3.31 3.43zM8.87 17.94c-1.51 1.01-1.51 2.67 0 3.67 1.72 1.15 4.54 1.15 6.26 0 1.51-1.01 1.51-2.67 0-3.67-1.71-1.15-4.53-1.15-6.26 0z"
                      ></path>
                    </svg>
                  </Link>
                </li>
              ) : null} */}
            </ul>
            <ul className="admin-ui-sidebar-list list-unstyled">
              <li>
                <Link
                  to="/settings"
                  className={`${
                    location.pathname.includes("settings") && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="none"
                    viewBox="0 0 25 24"
                  >
                    <path
                      fill="#656565"
                      d="M2.016 12.88v-1.76c0-1.04.85-1.9 1.9-1.9 1.81 0 2.55-1.28 1.64-2.85-.52-.9-.21-2.07.7-2.59l1.73-.99c.79-.47 1.81-.19 2.28.6l.11.19c.9 1.57 2.38 1.57 3.29 0l.11-.19c.47-.79 1.49-1.07 2.28-.6l1.73.99c.91.52 1.22 1.69.7 2.59-.91 1.57-.17 2.85 1.64 2.85 1.04 0 1.9.85 1.9 1.9v1.76c0 1.04-.85 1.9-1.9 1.9-1.81 0-2.55 1.28-1.64 2.85.52.91.21 2.07-.7 2.59l-1.73.99c-.79.47-1.81.19-2.28-.6l-.11-.19c-.9-1.57-2.38-1.57-3.29 0l-.11.19c-.47.79-1.49 1.07-2.28.6l-1.73-.99a1.899 1.899 0 01-.7-2.59c.91-1.57.17-2.85-1.64-2.85-1.05 0-1.9-.86-1.9-1.9z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M12.016 15.25a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5z"
                    ></path>
                  </svg>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-settings"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" /><path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" /></svg> */}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
