import React, { useEffect, useRef, useState } from "react";
import { InputGroup, Row, Col, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-multi-lang";
import { updatePersonalInformationStart } from "../../../store/slices/AdminSlice";
import { ButtonLoader } from "../../Helper/Loader";
import { countries } from "countries-list";
import Select from "react-select";
import InputIcon from "react-multi-date-picker/components/input_icon";

const PersonalInformation = (props) => {
  const [value, setValue] = useState();
  const t = useTranslation("onboarding.onboarding_form.personal_information");
  const formRef = useRef();
  const dispatch = useDispatch();
  const updatePersonalInformation = useSelector(
    (state) => state.admin.updatePersonalInformation
  );
  const [skipRender, setSkipRender] = useState(true);
  const profile = useSelector((state) => state.admin.profile);

  const validationSchema = Yup.object().shape({
    dob: Yup.string().required(t("required")),
    gender: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    nationality: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    service_type: Yup.string().required(t("required")),
    user_type: Yup.string().when("service_type", {
      is: "2",
      then: Yup.string().required(t("required")),
      otherwise: Yup.string(),
    }),
  });

  const businessValidationSchema = Yup.object().shape({
    dob: Yup.string().required(t("required")),
    gender: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    nationality: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
  });

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 450,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "6px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f7f7f7",
          color: "#000",
        },
      };
    },
  };

  const handleSubmit = (values) => {
    dispatch(updatePersonalInformationStart(values.service_type == 1 ? {
      ...values,
      user_type: 1,

    } : values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !updatePersonalInformation.loading &&
      Object.keys(updatePersonalInformation.data).length > 0
    ) {
      props.setStep(props.step + 1);
    }
    setSkipRender(false);
  }, [updatePersonalInformation]);

  const countryOptions = Object.keys(countries)
    .filter(
      (country) =>
        ![
          "AF",
          "BY",
          "CF",
          "CN",
          "CU",
          "CD",
          "ET",
          "IN",
          "IR",
          "IQ",
          "LY",
          "ML",
          "NI",
          "KP",
          "SO",
          "SS",
          "SD",
          "SY",
          "US",
          "VE",
          "YE",
          "ZW",
        ].includes(country)
    )
    .map((alpha2) => ({
      value: alpha2,
      label: countries[alpha2].name,
    }));

  return (
    <div className="onboarding-auth">
      <div className="onboarding-auth-header resp-mrg-btm-xs">
        <h3>{t("heading")}</h3>
        <p>{t("content")}</p>
      </div>
      <Formik
        initialValues={{
          dob: profile.data.dob || "",
          gender: profile.data.gender,
          nationality: profile.data.nationality,
          service_type: profile.data.service_type || 2,
          user_type: profile.data.user_type,
        }}
        validationSchema={profile.data.is_sub_user == 0 ? validationSchema : businessValidationSchema}
        onSubmit={handleSubmit}
        innerRef={formRef}
      >
        {({ setFieldValue, values, touched, errors }) => (
          <FORM className="efi-bank-default-form w-100">
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="default-form-label w-100">
                    {t("gender")} *
                  </Form.Label>
                  <div className="gender-check-box">
                    <div className="pretty p-default p-round">
                      <input
                        type="radio"
                        name="flexRadioDefault"
                        id="male"
                        checked={values.gender == "M"}
                        onChange={() => setFieldValue("gender", "M")}
                      />
                      <div className="state p-primary-o">
                        <label> {t("male")}</label>
                      </div>
                    </div>

                    <div className="pretty p-default p-round">
                      <input
                        type="radio"
                        name="flexRadioDefault"
                        id="female"
                        checked={values.gender == "F"}
                        onChange={() => setFieldValue("gender", "F")}
                      />
                      <div className="state p-primary-o">
                        <label> {t("female")}</label>
                      </div>
                    </div>
                    <div className="pretty p-default p-round">
                      <input
                        type="radio"
                        name="flexRadioDefault"
                        id="other"
                        checked={values.gender == "O"}
                        onChange={() => setFieldValue("gender", "O")}
                      />
                      <div className="state p-primary-o">
                        <label> {t("others")}</label>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage
                    component={"div"}
                    name="gender"
                    className="errorMsg"
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="default-form-label w-100">
                    {t("dob.name")} *
                  </Form.Label>
                  <div className="single-date-picker">
                    <InputGroup className="mb-0">
                      <DatePicker
                        minDate={new Date("1900-01-01")}
                        maxDate={new Date(new Date("2006-08-27").setHours(23, 59, 59, 999))}
                        value={values.dob}
                        onChange={(e) => setFieldValue("dob", e ? e : "")}
                        placeholder={t("dob.placeholder")}
                        width="100%"
                        format="YYYY-MM-DD"
                        id="date-picker-input"
                        editable={false}
                        onOpenPickNewDate={false}
                      />
                      <InputGroup.Text
                        onClick={() => {
                          const input =
                            document.getElementById("date-picker-input");
                          if (input) {
                            input.focus();
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="none"
                          viewBox="0 0 18 18"
                        >
                          <g clipPath="url(#clip0_47_6)">
                            <path
                              fill="#8F9899"
                              d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_47_6">
                              <path fill="#fff" d="M0 0H18V18H0z"></path>
                            </clipPath>
                          </defs>
                        </svg>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <ErrorMessage
                    component={"div"}
                    name="dob"
                    className="errorMsg"
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="default-form-label w-100">
                    {t("nationality.name")} *
                  </Form.Label>
                  <Select
                    options={countryOptions}
                    styles={customStyles}
                    classNamePrefix="react-select"
                    onChange={(selectedOption) =>
                      setFieldValue("nationality", selectedOption.value)
                    }
                    value={countryOptions.find(
                      (option) => option.value === values.nationality
                    )}
                    placeholder={t("nationality.placeholder")}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="nationality"
                    className="errorMsg"
                  />
                </Form.Group>
              </Col>
              {profile.data.is_sub_user == 0 ? <React.Fragment>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("service_type")} *
                    </Form.Label>
                    <div className="services-card-wrapped">
                      {/* <div class="form-check p-0">
                        <span>{t("card")}</span>
                        <label class="form-check-label" for="1">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="service_type"
                            id="1"
                            disabled={profile.data.service_type}
                            checked={values.service_type == 1}
                            onChange={() => setFieldValue("service_type", 1)}
                          />

                          <div className="service-card-custome">
                            <div className="check-tick">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 520 520"
                              >
                                <path
                                  d="M79.423 240.755a47.529 47.529 0 00-36.737 77.522l120.73 147.894a43.136 43.136 0 0036.066 16.009c14.654-.787 27.884-8.626 36.319-21.515L486.588 56.773a6.13 6.13 0 01.128-.2c2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 00-19.362 1.343q-.135.166-.278.327L210.887 328.736a10.961 10.961 0 01-15.585.843l-83.94-76.386a47.319 47.319 0 00-31.939-12.438z"
                                  data-name="7-Check"
                                ></path>
                              </svg>
                            </div>
                            <Image
                              src={
                                window.location.origin + "/img/feature/card.svg"
                              }
                              className="card-avater"
                            />
                          </div>
                        </label>
                      </div> */}
                      <div class="form-check p-0">
                        <span>{t("bank")}</span>
                        <label class="form-check-label" for="2">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="service_type"
                            id="2"
                            disabled={profile.data.service_type}
                            checked={values.service_type == 2}
                            onChange={() => setFieldValue("service_type", 2)}
                          />
                          <div className="service-card-custome">
                            <div className="check-tick">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 520 520"
                              >
                                <path
                                  d="M79.423 240.755a47.529 47.529 0 00-36.737 77.522l120.73 147.894a43.136 43.136 0 0036.066 16.009c14.654-.787 27.884-8.626 36.319-21.515L486.588 56.773a6.13 6.13 0 01.128-.2c2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 00-19.362 1.343q-.135.166-.278.327L210.887 328.736a10.961 10.961 0 01-15.585.843l-83.94-76.386a47.319 47.319 0 00-31.939-12.438z"
                                  data-name="7-Check"
                                ></path>
                              </svg>
                            </div>
                            <Image
                              src={
                                window.location.origin + "/img/feature/bank.svg"
                              }
                              className="card-avater"
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                    <ErrorMessage
                      component={"div"}
                      name="service_type"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
                {values.service_type == 2 ? <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("account_type")}
                    </Form.Label>
                    <div className="gender-check-box">
                      <div className="pretty p-default p-round">
                        <input name="user_type" type="radio" disabled={profile.data.user_type} checked={values.user_type == 1} onChange={() => setFieldValue("user_type", "1")} />
                        <div className="state p-primary-o">
                          <label>{t("individual")}</label>
                        </div>
                      </div>
                      <div className="pretty p-default p-round">
                        <input name="user_type" type="radio" disabled={profile.data.user_type} checked={values.user_type == 2} onChange={() => setFieldValue("user_type", "2")} />
                        <div className="state p-primary-o">
                          <label>{t("business")}</label>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage
                      component={"div"}
                      name="user_type"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col> : null}
              </React.Fragment> : null}
            </Row>
            <div className="onboarding-auth-footer pt-3">
              <div className="onboarding-auth-footer-skip">
                <Link to={-1} className="efi-overlay-btn" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#0194ff"
                      d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"
                      data-original="#000000"
                    ></path>
                  </svg>
                  <span>{t("back")}</span>
                </Link>
              </div>
              <div className="space-tow">
                <Button
                  className="efi-primary-btn"
                  type="submit"
                  disabled={updatePersonalInformation.buttonDisable}
                // onClick={() => props.setStep(3)}
                >
                  {updatePersonalInformation.buttonDisable ? (
                    <ButtonLoader varient="black" />
                  ) : (
                    t("continue")
                  )}
                </Button>
              </div>
            </div>
          </FORM>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInformation;
