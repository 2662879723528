import React from "react";
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "./Loader";
import useLogout from "../hooks/useLogout";

const LogoutConfirmationModal = (props) => {
  const dispatch = useDispatch();
  const { logout } = useLogout();

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="card-sendmoney-modal"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-card-title">
            <h3 className="change-password-heading">Logout Confirmation</h3>
          </div>
          <div className="transfer-successful-icon-sec mb-4">
            <Image
              className="logout-icon"
              src={window.location.origin + "/img/logout.svg"}
            />
          </div>
          <div className="efi-bank-default-form w-100">
            <div className="onboarding-auth-footer">
              <Button
                className="efi-primary-btn w-100"
                type="submit"
                onClick={logout}
                disabled={logout.buttonDisable}
              >
                {"Logout"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LogoutConfirmationModal;
