import React, { useState, useEffect } from "react";
import { Form, Button, Image, Row, Col } from "react-bootstrap";
import OnboardingWelcome from "./OnboardingWelcome";
import BusinessInformation from "./OnboardingForm/BusinessInformation";
import BillingAddress from "./OnboardingForm/BillingAddress";
import PersonalInformation from "./OnboardingForm/PersonalInformation";
import SuccessPage from "./OnboardingForm/SuccessPage";
import { useDispatch, useSelector } from "react-redux";
import OnboardingKyc from "./OnboardingForm/OnboardingKyc";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import KycManual from "./OnboardingForm/KycManual";

const OnboardingIndex = (props) => {
  const t = useTranslation("onboarding.onboarding_index");
  const [step, setStep] = useState(1);
  const [completed, setCompleted] = useState(1);
  const profile = useSelector((state) => state.admin.profile);

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length > 0) {
      setStep(step > profile.data.onboarding ? step : profile.data.onboarding);
      // setStep(0)
      setCompleted(profile.data.onboarding);
    }
  }, [profile]);

  return (
    <>
      <div className="efibank-onboarding-feature-wrapped">
        <div className="efibank-onboarding-feature-step-wrap">
          <div className="onboarding-feature-logo">
            <Image
              src={window.location.origin + "/img/efi-bank-white-logo.png"}
              className="feature-logo"
            />
          </div>
          <div className="onboarding-feature-step-frames">
            <div className="onboarding-feature-step-frames-info d-flex flex-column gap-3">
              <h3>
                Step{" "}
                {step == 1
                  ? 1
                  : step == 2
                  ? 2
                  : step == 3
                  ? 3
                  : step == 4
                  ? 4
                  : null}
              </h3>
              <p>{t("content")}</p>
            </div>
            <div className="onboarding-feature-step-list">
              <ul className="list-unstyled">
                <li
                  className={`feature-list-step-card ${
                    completed > 1 ? "completed" : ""
                  } ${step == 1 ? "active" : ""}`}
                >
                  <div className="feature-step-count">
                    {completed > 1 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 520 520"
                      >
                        <path
                          fill="#fff"
                          d="M239.987 460.841a10 10 0 01-7.343-3.213L34.657 243.463A10 10 0 0142 226.675h95.3a10.006 10.006 0 017.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0110.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 01-9.707 7.6z"
                          data-name="6-Check"
                          data-original="#000000"
                        ></path>
                      </svg>
                    ) : (
                      1
                    )}
                  </div>
                  <div className="feature-step-title">
                    <p>{t("personal_identification")}</p>
                  </div>
                </li>
                <div className="feature-line"></div>
                <li
                  className={`feature-list-step-card ${
                    completed > 2 ? "completed" : ""
                  } ${step == 2 ? "active" : ""}`}
                >
                  <div className="feature-step-count">
                    {completed > 2 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 520 520"
                      >
                        <path
                          fill="#fff"
                          d="M239.987 460.841a10 10 0 01-7.343-3.213L34.657 243.463A10 10 0 0142 226.675h95.3a10.006 10.006 0 017.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0110.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 01-9.707 7.6z"
                          data-name="6-Check"
                          data-original="#000000"
                        ></path>
                      </svg>
                    ) : (
                      2
                    )}
                  </div>
                  <div className="feature-step-title">
                    <p>
                      {profile.data.user_type != 2
                        ? t("billing_delivery_address")
                        : t("business_information")}
                    </p>
                  </div>
                </li>
                <div className="feature-line"></div>
                <li
                  className={`feature-list-step-card ${
                    completed > 3 ? "completed" : ""
                  } ${step == 3 ? "active" : ""}`}
                >
                  <div className="feature-step-count">
                    {completed > 3 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 520 520"
                      >
                        <path
                          fill="#fff"
                          d="M239.987 460.841a10 10 0 01-7.343-3.213L34.657 243.463A10 10 0 0142 226.675h95.3a10.006 10.006 0 017.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0110.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 01-9.707 7.6z"
                          data-name="6-Check"
                          data-original="#000000"
                        ></path>
                      </svg>
                    ) : (
                      3
                    )}
                  </div>
                  <div className="feature-step-title">
                    <p>{t("kyc_verification")}</p>
                  </div>
                </li>
                <div className="feature-line"></div>
                <li
                  className={`feature-list-step-card ${
                    completed >= 4 ? "completed" : ""
                  } ${step == 4 ? "active" : ""}`}
                >
                  <div className="feature-step-count">
                    {completed >= 4 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 520 520"
                      >
                        <path
                          fill="#fff"
                          d="M239.987 460.841a10 10 0 01-7.343-3.213L34.657 243.463A10 10 0 0142 226.675h95.3a10.006 10.006 0 017.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0110.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 01-9.707 7.6z"
                          data-name="6-Check"
                          data-original="#000000"
                        ></path>
                      </svg>
                    ) : (
                      4
                    )}
                  </div>
                  <div className="feature-step-title">
                    <p>{t("complete")}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="efibank-onboarding-feature-step-details">
          <div className="skip-frame-action">
            <Link className="efi-overlay-btn" to="/">
              {t("skip_onboarding")}
            </Link>
            <Link to="/account" className="efi-primary-btn">
              {t("home")}
            </Link>
          </div>
          <div
            className={`${
              step == 3 || step == 2
                ? "feature-grid-layout"
                : "feature-grid-layout-two"
            }`}
          >
            <div className="efi-bank-feature-form-frame w-100">
              {step == 1 ? (
                <PersonalInformation setStep={setStep} step={step} />
              ) : step == 2 ? (
                profile.data.user_type == 1 ? (
                  <BillingAddress setStep={setStep} step={step} />
                ) : (
                  <BusinessInformation setStep={setStep} step={step} />
                )
              ) : step == 3 ? (
                profile.data.user_type == 1 ? (
                  <OnboardingKyc setStep={setStep} step={step} />
                ) : (
                  <KycManual step={step} setStep={setStep} />
                )
              ) : step == 4 ? (
                <SuccessPage />
              ) : // <PersonalInformation setStep={setStep} step={step} />
              null}
            </div>
            <div className="efi-bank-feature-form-avater">
              <div className="where-can-we-apply-left-sec">
                {step == 1 ? (
                  <Image
                    src={
                      window.location.origin +
                      "/img/feature/onboarding/illustration-one.svg"
                    }
                    className="illustration-avater"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardingIndex;
